const api = {
  "openapi": "3.0.3",
  "components": {
    "schemas": {
      "active_storage_attachment": {
        "x-rhino-model": {
          "model": "active_storage_attachment",
          "modelPlural": "active_storage/attachments",
          "name": "activeStorage::Attachment",
          "pluralName": "activeStorage::Attachments",
          "readableName": "Attachment",
          "pluralReadableName": "Attachments",
          "ownedBy": null,
          "singular": false,
          "path": "/api/attachments",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "record_type": {
            "x-rhino-attribute": {
              "name": "record_type",
              "readableName": "Record Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "url": {
            "x-rhino-attribute": {
              "name": "url",
              "readableName": "Url",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "url_attachment": {
            "x-rhino-attribute": {
              "name": "url_attachment",
              "readableName": "Url Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "url_thumbnail": {
            "x-rhino-attribute": {
              "name": "url_thumbnail",
              "readableName": "Url Thumbnail",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "signed_id": {
            "x-rhino-attribute": {
              "name": "signed_id",
              "readableName": "Signed",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "unknown"
          }
        },
        "required": [
          "name",
          "record_type"
        ]
      },
      "user": {
        "x-rhino-model": {
          "model": "user",
          "modelPlural": "users",
          "name": "user",
          "pluralName": "users",
          "readableName": "User",
          "pluralReadableName": "Users",
          "ownedBy": null,
          "singular": false,
          "path": "/api/users",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "nickname": {
            "x-rhino-attribute": {
              "name": "nickname",
              "readableName": "Nickname",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "email": {
            "x-rhino-attribute": {
              "name": "email",
              "readableName": "Email",
              "readable": true,
              "creatable": true,
              "updatable": false
            },
            "nullable": false,
            "type": "string",
            "pattern": "^[^@\\s]+@[^@\\s]+$"
          },
          "image": {
            "x-rhino-attribute": {
              "name": "image",
              "readableName": "Image",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "string"
          }
        },
        "required": [
          "email"
        ]
      },
      "account": {
        "x-rhino-model": {
          "model": "account",
          "modelPlural": "accounts",
          "name": "account",
          "pluralName": "accounts",
          "readableName": "Account",
          "pluralReadableName": "Accounts",
          "ownedBy": "global",
          "singular": true,
          "path": "/api/account",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "nickname": {
            "x-rhino-attribute": {
              "name": "nickname",
              "readableName": "Nickname",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "email": {
            "x-rhino-attribute": {
              "name": "email",
              "readableName": "Email",
              "readable": true,
              "creatable": true,
              "updatable": false
            },
            "nullable": false,
            "type": "string",
            "pattern": "^[^@\\s]+@[^@\\s]+$"
          },
          "image": {
            "x-rhino-attribute": {
              "name": "image",
              "readableName": "Image",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "string"
          },
          "users_roles": {
            "x-rhino-attribute": {
              "name": "users_roles",
              "readableName": "Users Roles",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/users_role"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "email"
        ]
      },
      "currency": {
        "x-rhino-model": {
          "model": "currency",
          "modelPlural": "currencies",
          "name": "currency",
          "pluralName": "currencies",
          "readableName": "Currency",
          "pluralReadableName": "Currencies",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/currencies",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "currency_code": {
            "x-rhino-attribute": {
              "name": "currency_code",
              "readableName": "Currency Code",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "currency_name": {
            "x-rhino-attribute": {
              "name": "currency_name",
              "readableName": "Currency Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "symbol": {
            "x-rhino-attribute": {
              "name": "symbol",
              "readableName": "Symbol",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "country_or_region": {
            "x-rhino-attribute": {
              "name": "country_or_region",
              "readableName": "Country Or Region",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "decimal_places": {
            "x-rhino-attribute": {
              "name": "decimal_places",
              "readableName": "Decimal Places",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "currency_unit": {
            "x-rhino-attribute": {
              "name": "currency_unit",
              "readableName": "Currency Unit",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "subunit": {
            "x-rhino-attribute": {
              "name": "subunit",
              "readableName": "Subunit",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          }
        },
        "required": [
          "currency_code"
        ]
      },
      "organization": {
        "x-rhino-model": {
          "model": "organization",
          "modelPlural": "organizations",
          "name": "organization",
          "pluralName": "organizations",
          "readableName": "Organization",
          "pluralReadableName": "Organizations",
          "ownedBy": null,
          "singular": false,
          "path": "/api/organizations",
          "searchable": true
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "external_id": {
            "x-rhino-attribute": {
              "name": "external_id",
              "readableName": "External",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "text"
          },
          "users_roles": {
            "x-rhino-attribute": {
              "name": "users_roles",
              "readableName": "Users Roles",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/users_role"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        }
      },
      "users_role": {
        "x-rhino-model": {
          "model": "users_role",
          "modelPlural": "users_roles",
          "name": "usersRole",
          "pluralName": "usersRoles",
          "readableName": "Users role",
          "pluralReadableName": "Users roles",
          "ownedBy": "organization",
          "singular": false,
          "path": "/api/users_roles",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "user": {
            "x-rhino-attribute": {
              "name": "user",
              "readableName": "User",
              "readable": true,
              "creatable": true,
              "updatable": false
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/user"
              }
            ]
          },
          "role": {
            "x-rhino-attribute": {
              "name": "role",
              "readableName": "Role",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/role"
              }
            ]
          },
          "organization": {
            "x-rhino-attribute": {
              "name": "organization",
              "readableName": "Organization",
              "readable": true,
              "creatable": true,
              "updatable": false
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/organization"
              }
            ]
          }
        },
        "required": [
          "user",
          "role",
          "organization"
        ]
      },
      "role": {
        "x-rhino-model": {
          "model": "role",
          "modelPlural": "roles",
          "name": "role",
          "pluralName": "roles",
          "readableName": "Role",
          "pluralReadableName": "Roles",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/roles",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string",
            "pattern": "^[a-zA-Z]+$"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          }
        },
        "required": [
          "name"
        ]
      },
      "users_role_invite": {
        "x-rhino-model": {
          "model": "users_role_invite",
          "modelPlural": "users_role_invites",
          "name": "usersRoleInvite",
          "pluralName": "usersRoleInvites",
          "readableName": "Users role invite",
          "pluralReadableName": "Users role invites",
          "ownedBy": "organization",
          "singular": false,
          "path": "/api/users_role_invites",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "email": {
            "x-rhino-attribute": {
              "name": "email",
              "readableName": "Email",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "role": {
            "x-rhino-attribute": {
              "name": "role",
              "readableName": "Role",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/role"
              }
            ]
          },
          "organization": {
            "x-rhino-attribute": {
              "name": "organization",
              "readableName": "Organization",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/organization"
              }
            ]
          }
        },
        "required": [
          "email",
          "role",
          "organization"
        ]
      },
      "site": {
        "x-rhino-model": {
          "model": "site",
          "modelPlural": "sites",
          "name": "site",
          "pluralName": "sites",
          "readableName": "Site",
          "pluralReadableName": "Sites",
          "ownedBy": "organization",
          "singular": false,
          "path": "/api/sites",
          "searchable": true
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "external_id": {
            "x-rhino-attribute": {
              "name": "external_id",
              "readableName": "External",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "customer": {
            "x-rhino-attribute": {
              "name": "customer",
              "readableName": "Customer",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/organization"
              }
            ]
          },
          "organization": {
            "x-rhino-attribute": {
              "name": "organization",
              "readableName": "Organization",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/organization"
              }
            ]
          }
        },
        "required": [
          "organization"
        ]
      },
      "building": {
        "x-rhino-model": {
          "model": "building",
          "modelPlural": "buildings",
          "name": "building",
          "pluralName": "buildings",
          "readableName": "Building",
          "pluralReadableName": "Buildings",
          "ownedBy": "site",
          "singular": false,
          "path": "/api/buildings",
          "searchable": true
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "address": {
            "x-rhino-attribute": {
              "name": "address",
              "readableName": "Address",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "text"
          },
          "city": {
            "x-rhino-attribute": {
              "name": "city",
              "readableName": "City",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "state": {
            "x-rhino-attribute": {
              "name": "state",
              "readableName": "State",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "country": {
            "x-rhino-attribute": {
              "name": "country",
              "readableName": "Country",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "CA",
            "type": "string",
            "format": "country"
          },
          "latitude": {
            "x-rhino-attribute": {
              "name": "latitude",
              "readableName": "Latitude",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "float"
          },
          "longitude": {
            "x-rhino-attribute": {
              "name": "longitude",
              "readableName": "Longitude",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "float"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "expiry_date": {
            "x-rhino-attribute": {
              "name": "expiry_date",
              "readableName": "Expiry Date",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "format": "datetime"
          },
          "external_id": {
            "x-rhino-attribute": {
              "name": "external_id",
              "readableName": "External",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "searchable_en": {
            "x-rhino-attribute": {
              "name": "searchable_en",
              "readableName": "Searchable En",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "tsvector"
          },
          "site": {
            "x-rhino-attribute": {
              "name": "site",
              "readableName": "Site",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/site"
              }
            ]
          },
          "building_type": {
            "x-rhino-attribute": {
              "name": "building_type",
              "readableName": "Building Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_type"
              }
            ]
          },
          "building_operations": {
            "x-rhino-attribute": {
              "name": "building_operations",
              "readableName": "Building Operations",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/building_operation"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "customer_logo_attachment": {
            "x-rhino-attribute": {
              "name": "customer_logo_attachment",
              "readableName": "Customer Logo Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ],
            "format": "image"
          }
        },
        "required": [
          "name",
          "address",
          "city",
          "state",
          "site",
          "building_type"
        ]
      },
      "building_type": {
        "x-rhino-model": {
          "model": "building_type",
          "modelPlural": "building_types",
          "name": "buildingType",
          "pluralName": "buildingTypes",
          "readableName": "Building type",
          "pluralReadableName": "Building types",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/building_types",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          }
        },
        "required": [
          "name"
        ]
      },
      "building_code": {
        "x-rhino-model": {
          "model": "building_code",
          "modelPlural": "building_codes",
          "name": "buildingCode",
          "pluralName": "buildingCodes",
          "readableName": "Building code",
          "pluralReadableName": "Building codes",
          "ownedBy": "building_type",
          "singular": false,
          "path": "/api/building_codes",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "level": {
            "x-rhino-attribute": {
              "name": "level",
              "readableName": "Level",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "enum": [
              "national",
              "state",
              "city"
            ]
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "building_type": {
            "x-rhino-attribute": {
              "name": "building_type",
              "readableName": "Building Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_type"
              }
            ]
          }
        },
        "required": [
          "name",
          "building_type"
        ]
      },
      "building_code_uvalue": {
        "x-rhino-model": {
          "model": "building_code_uvalue",
          "modelPlural": "building_code_uvalues",
          "name": "buildingCodeUvalue",
          "pluralName": "buildingCodeUvalues",
          "readableName": "Building code uvalue",
          "pluralReadableName": "Building code uvalues",
          "ownedBy": "building_code",
          "singular": false,
          "path": "/api/building_code_uvalues",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "u_value": {
            "x-rhino-attribute": {
              "name": "u_value",
              "readableName": "U Value",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 0.0,
            "maximum": 5.0
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "net_zero": {
            "x-rhino-attribute": {
              "name": "net_zero",
              "readableName": "Net Zero",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "building_code": {
            "x-rhino-attribute": {
              "name": "building_code",
              "readableName": "Building Code",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_code"
              }
            ]
          },
          "base_element": {
            "x-rhino-attribute": {
              "name": "base_element",
              "readableName": "Base Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/base_element"
              }
            ]
          }
        },
        "required": [
          "u_value",
          "building_code",
          "base_element"
        ]
      },
      "building_access": {
        "x-rhino-model": {
          "model": "building_access",
          "modelPlural": "building_accesses",
          "name": "buildingAccess",
          "pluralName": "buildingAccesses",
          "readableName": "Building access",
          "pluralReadableName": "Building accesses",
          "ownedBy": "building",
          "singular": false,
          "path": "/api/building_accesses",
          "searchable": true
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "email": {
            "x-rhino-attribute": {
              "name": "email",
              "readableName": "Email",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "building": {
            "x-rhino-attribute": {
              "name": "building",
              "readableName": "Building",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building"
              }
            ]
          },
          "user": {
            "x-rhino-attribute": {
              "name": "user",
              "readableName": "User",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/user"
              }
            ]
          }
        },
        "required": [
          "building",
          "user"
        ]
      },
      "zone": {
        "x-rhino-model": {
          "model": "zone",
          "modelPlural": "zones",
          "name": "zone",
          "pluralName": "zones",
          "readableName": "Zone",
          "pluralReadableName": "Zones",
          "ownedBy": "building_operation",
          "singular": false,
          "path": "/api/zones",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "zone_index": {
            "x-rhino-attribute": {
              "name": "zone_index",
              "readableName": "Zone Index",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "inside_temp": {
            "x-rhino-attribute": {
              "name": "inside_temp",
              "readableName": "Inside Temp (°C)",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "maximum": 30.0,
            "exclusiveMaximum": true,
            "minimum": 10.0
          },
          "set_point": {
            "x-rhino-attribute": {
              "name": "set_point",
              "readableName": "Set Point",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "is_delta_ts_from_core": {
            "x-rhino-attribute": {
              "name": "is_delta_ts_from_core",
              "readableName": "Is Delta Ts From Core",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "area": {
            "x-rhino-attribute": {
              "name": "area",
              "readableName": "Area",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": {
            },
            "type": "json"
          },
          "building_operation": {
            "x-rhino-attribute": {
              "name": "building_operation",
              "readableName": "Building Operation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_operation"
              }
            ]
          },
          "delta_ts_file_attachment": {
            "x-rhino-attribute": {
              "name": "delta_ts_file_attachment",
              "readableName": "Delta Ts File Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          }
        },
        "required": [
          "inside_temp",
          "building_operation"
        ]
      },
      "facade": {
        "x-rhino-model": {
          "model": "facade",
          "modelPlural": "facades",
          "name": "facade",
          "pluralName": "facades",
          "readableName": "Facade",
          "pluralReadableName": "Facades",
          "ownedBy": "building_operation",
          "singular": false,
          "path": "/api/facades",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "facade_index": {
            "x-rhino-attribute": {
              "name": "facade_index",
              "readableName": "Facade Index",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true,
            "format": "customn_select"
          },
          "riser_count": {
            "x-rhino-attribute": {
              "name": "riser_count",
              "readableName": "Riser Count",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 1,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true,
            "maximum": 51,
            "exclusiveMaximum": true
          },
          "level_count": {
            "x-rhino-attribute": {
              "name": "level_count",
              "readableName": "Level Count",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 1,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true,
            "maximum": 51,
            "exclusiveMaximum": true
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "windspeed": {
            "x-rhino-attribute": {
              "name": "windspeed",
              "readableName": "Windspeed (m/s)",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "maximum": 20.0,
            "exclusiveMaximum": true,
            "minimum": 0.0
          },
          "outside_temp": {
            "x-rhino-attribute": {
              "name": "outside_temp",
              "readableName": "Outside Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": -100.0,
            "maximum": 100.0
          },
          "humidity": {
            "x-rhino-attribute": {
              "name": "humidity",
              "readableName": "Humidity",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 20.0,
            "maximum": 100.0
          },
          "measured_area": {
            "x-rhino-attribute": {
              "name": "measured_area",
              "readableName": "Measured Area",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "area": {
            "x-rhino-attribute": {
              "name": "area",
              "readableName": "Area",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": {
            },
            "type": "json"
          },
          "image_count": {
            "x-rhino-attribute": {
              "name": "image_count",
              "readableName": "Image Count",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0,
            "type": "integer"
          },
          "building_image_count": {
            "x-rhino-attribute": {
              "name": "building_image_count",
              "readableName": "Building Image Count",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0,
            "type": "integer"
          },
          "zone_index": {
            "x-rhino-attribute": {
              "name": "zone_index",
              "readableName": "Zone Index",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "building_operation": {
            "x-rhino-attribute": {
              "name": "building_operation",
              "readableName": "Building Operation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_operation"
              }
            ]
          },
          "cells": {
            "x-rhino-attribute": {
              "name": "cells",
              "readableName": "Cells",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/cell"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "zone": {
            "x-rhino-attribute": {
              "name": "zone",
              "readableName": "Zone",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/zone"
              }
            ]
          },
          "elements": {
            "x-rhino-attribute": {
              "name": "elements",
              "readableName": "Elements",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/element"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "facade_element_details": {
            "x-rhino-attribute": {
              "name": "facade_element_details",
              "readableName": "Facade Element Details",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              ],
              "x-rhino-attribute-array": {
                "creatable": true,
                "updatable": true,
                "destroyable": false
              }
            }
          },
          "visual_image_attachment": {
            "x-rhino-attribute": {
              "name": "visual_image_attachment",
              "readableName": "Visual Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ],
            "format": "image"
          },
          "image_attachments": {
            "x-rhino-attribute": {
              "name": "image_attachments",
              "readableName": "Image Attachments",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "reports": {
            "x-rhino-attribute": {
              "name": "reports",
              "readableName": "Reports",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/report"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "riser_count",
          "level_count",
          "windspeed",
          "outside_temp",
          "humidity",
          "building_operation",
          "zone"
        ]
      },
      "cell": {
        "x-rhino-model": {
          "model": "cell",
          "modelPlural": "cells",
          "name": "cell",
          "pluralName": "cells",
          "readableName": "Cell",
          "pluralReadableName": "Cells",
          "ownedBy": "facade",
          "singular": false,
          "path": "/api/cells",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "riser_index": {
            "x-rhino-attribute": {
              "name": "riser_index",
              "readableName": "Riser Index",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true
          },
          "level_index": {
            "x-rhino-attribute": {
              "name": "level_index",
              "readableName": "Level Index",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "exists": {
            "x-rhino-attribute": {
              "name": "exists",
              "readableName": "Exists",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "facade": {
            "x-rhino-attribute": {
              "name": "facade",
              "readableName": "Facade",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade"
              }
            ]
          },
          "override_visual_image_attachment": {
            "x-rhino-attribute": {
              "name": "override_visual_image_attachment",
              "readableName": "Override Visual Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "building_image": {
            "x-rhino-attribute": {
              "name": "building_image",
              "readableName": "Building Image",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_image"
              }
            ]
          },
          "reports": {
            "x-rhino-attribute": {
              "name": "reports",
              "readableName": "Reports",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/report"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "cell_element_details": {
            "x-rhino-attribute": {
              "name": "cell_element_details",
              "readableName": "Cell Element Details",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        }
      },
      "facade_element_detail": {
        "x-rhino-model": {
          "model": "facade_element_detail",
          "modelPlural": "facade_element_details",
          "name": "facadeElementDetail",
          "pluralName": "facadeElementDetails",
          "readableName": "Facade element detail",
          "pluralReadableName": "Facade element details",
          "ownedBy": "facade",
          "singular": false,
          "path": "/api/facade_element_details",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "area": {
            "x-rhino-attribute": {
              "name": "area",
              "readableName": "Area",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 0.0
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "exists": {
            "x-rhino-attribute": {
              "name": "exists",
              "readableName": "Exists",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "facade": {
            "x-rhino-attribute": {
              "name": "facade",
              "readableName": "Facade",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade"
              }
            ]
          },
          "element": {
            "x-rhino-attribute": {
              "name": "element",
              "readableName": "Element",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/element"
              }
            ]
          },
          "facade_element_reflectives": {
            "x-rhino-attribute": {
              "name": "facade_element_reflectives",
              "readableName": "Facade Element Reflectives",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              ],
              "x-rhino-attribute-array": {
                "creatable": true,
                "updatable": true,
                "destroyable": true
              }
            }
          }
        },
        "required": [
          "area"
        ]
      },
      "cell_element_detail": {
        "x-rhino-model": {
          "model": "cell_element_detail",
          "modelPlural": "cell_element_details",
          "name": "cellElementDetail",
          "pluralName": "cellElementDetails",
          "readableName": "Cell element detail",
          "pluralReadableName": "Cell element details",
          "ownedBy": "cell",
          "singular": false,
          "path": "/api/cell_element_details",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "cell": {
            "x-rhino-attribute": {
              "name": "cell",
              "readableName": "Cell",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/cell"
              }
            ]
          },
          "base_element": {
            "x-rhino-attribute": {
              "name": "base_element",
              "readableName": "Base Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/base_element"
              }
            ]
          },
          "element": {
            "x-rhino-attribute": {
              "name": "element",
              "readableName": "Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/element"
              }
            ]
          },
          "facade_element_detail": {
            "x-rhino-attribute": {
              "name": "facade_element_detail",
              "readableName": "Facade Element Detail",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade_element_detail"
              }
            ]
          },
          "facade_element_reflective": {
            "x-rhino-attribute": {
              "name": "facade_element_reflective",
              "readableName": "Facade Element Reflective",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade_element_reflective"
              }
            ]
          },
          "thermal_map_attachment": {
            "x-rhino-attribute": {
              "name": "thermal_map_attachment",
              "readableName": "Thermal Map Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          }
        },
        "required": [
          "cell",
          "facade_element_reflective"
        ]
      },
      "facade_element_reflective": {
        "x-rhino-model": {
          "model": "facade_element_reflective",
          "modelPlural": "facade_element_reflectives",
          "name": "facadeElementReflective",
          "pluralName": "facadeElementReflectives",
          "readableName": "Facade element reflective",
          "pluralReadableName": "Facade element reflectives",
          "ownedBy": "facade_element_detail",
          "singular": false,
          "path": "/api/facade_element_reflectives",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "reflective_temp": {
            "x-rhino-attribute": {
              "name": "reflective_temp",
              "readableName": "Reflective Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": -40.0,
            "maximum": 500.0
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "facade_element_detail": {
            "x-rhino-attribute": {
              "name": "facade_element_detail",
              "readableName": "Facade Element Detail",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade_element_detail"
              }
            ]
          }
        },
        "required": [
          "name",
          "reflective_temp",
          "facade_element_detail"
        ]
      },
      "building_operation": {
        "x-rhino-model": {
          "model": "building_operation",
          "modelPlural": "building_operations",
          "name": "buildingOperation",
          "pluralName": "buildingOperations",
          "readableName": "Building operation",
          "pluralReadableName": "Building operations",
          "ownedBy": "building",
          "singular": false,
          "path": "/api/building_operations",
          "searchable": true
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "operation_at": {
            "x-rhino-attribute": {
              "name": "operation_at",
              "readableName": "Operation At",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "format": "datetime"
          },
          "outside_temp": {
            "x-rhino-attribute": {
              "name": "outside_temp",
              "readableName": "Outside Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": -100.0,
            "maximum": 100.0
          },
          "humidity": {
            "x-rhino-attribute": {
              "name": "humidity",
              "readableName": "Humidity",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 20.0,
            "maximum": 100.0
          },
          "operation_notes": {
            "x-rhino-attribute": {
              "name": "operation_notes",
              "readableName": "Operation Notes",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "zone_count": {
            "x-rhino-attribute": {
              "name": "zone_count",
              "readableName": "Zone Count",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 1,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true,
            "maximum": 10,
            "exclusiveMaximum": true
          },
          "facade_count": {
            "x-rhino-attribute": {
              "name": "facade_count",
              "readableName": "Facade Count",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 5,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true,
            "maximum": 50,
            "exclusiveMaximum": true
          },
          "status": {
            "x-rhino-attribute": {
              "name": "status",
              "readableName": "Status",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "technical",
            "type": "string",
            "enum": [
              "technical",
              "confirmed",
              "onsite",
              "imaging",
              "assessment",
              "complete",
              "portal",
              "portal_preview"
            ]
          },
          "status_changed_at": {
            "x-rhino-attribute": {
              "name": "status_changed_at",
              "readableName": "Status Changed At",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "ekw_heating": {
            "x-rhino-attribute": {
              "name": "ekw_heating",
              "readableName": "Ekw Heating",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 0.1,
            "type": "float",
            "minimum": 0.01,
            "maximum": 1.0
          },
          "report_summary": {
            "x-rhino-attribute": {
              "name": "report_summary",
              "readableName": "Report Summary",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "total_energy_consumption": {
            "x-rhino-attribute": {
              "name": "total_energy_consumption",
              "readableName": "Total Energy Consumption (MWh)",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 0.1
          },
          "visual_operation_at": {
            "x-rhino-attribute": {
              "name": "visual_operation_at",
              "readableName": "Visual Operation At",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "format": "datetime"
          },
          "report_completion_days": {
            "x-rhino-attribute": {
              "name": "report_completion_days",
              "readableName": "Report Completion Days",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 1,
            "type": "integer"
          },
          "report_data_points": {
            "x-rhino-attribute": {
              "name": "report_data_points",
              "readableName": "Report Data Points",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "report_thermal_duration_hours": {
            "x-rhino-attribute": {
              "name": "report_thermal_duration_hours",
              "readableName": "Report Thermal Duration Hours",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "report_visual_duration_hours": {
            "x-rhino-attribute": {
              "name": "report_visual_duration_hours",
              "readableName": "Report Visual Duration Hours",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "ekw_cooling": {
            "x-rhino-attribute": {
              "name": "ekw_cooling",
              "readableName": "Ekw Cooling",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 0.1,
            "type": "float",
            "minimum": 0.01,
            "maximum": 1.0
          },
          "external_id": {
            "x-rhino-attribute": {
              "name": "external_id",
              "readableName": "External",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "nira_model_path": {
            "x-rhino-attribute": {
              "name": "nira_model_path",
              "readableName": "Nira Model Path",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "default_formula_version": {
            "x-rhino-attribute": {
              "name": "default_formula_version",
              "readableName": "Default Formula Version",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "advanced",
            "type": "string",
            "enum": [
              "standard",
              "advanced"
            ]
          },
          "emission_snapshot_id": {
            "x-rhino-attribute": {
              "name": "emission_snapshot_id",
              "readableName": "Emission Snapshot",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "total_emission": {
            "x-rhino-attribute": {
              "name": "total_emission",
              "readableName": "Total Emission",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "heating_ratio": {
            "x-rhino-attribute": {
              "name": "heating_ratio",
              "readableName": "Heating Ratio",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0.0,
            "type": "float"
          },
          "cooling_ratio": {
            "x-rhino-attribute": {
              "name": "cooling_ratio",
              "readableName": "Cooling Ratio",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0.0,
            "type": "float"
          },
          "is_ml_project": {
            "x-rhino-attribute": {
              "name": "is_ml_project",
              "readableName": "Is Ml Project",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "lens": {
            "x-rhino-attribute": {
              "name": "lens",
              "readableName": "Lens",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "enum": [
              "f=10",
              "f=17",
              "f=29"
            ]
          },
          "area": {
            "x-rhino-attribute": {
              "name": "area",
              "readableName": "Area",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": {
            },
            "type": "json"
          },
          "building_name": {
            "x-rhino-attribute": {
              "name": "building_name",
              "readableName": "Building Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "",
            "type": "string"
          },
          "building_address": {
            "x-rhino-attribute": {
              "name": "building_address",
              "readableName": "Building Address",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "",
            "type": "string"
          },
          "thermal_hours": {
            "x-rhino-attribute": {
              "name": "thermal_hours",
              "readableName": "Thermal Hours",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "op_days": {
            "x-rhino-attribute": {
              "name": "op_days",
              "readableName": "Op Days",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "investigate_areas": {
            "x-rhino-attribute": {
              "name": "investigate_areas",
              "readableName": "Investigate Areas",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "monitor_areas": {
            "x-rhino-attribute": {
              "name": "monitor_areas",
              "readableName": "Monitor Areas",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "roof_facade_indexs": {
            "x-rhino-attribute": {
              "name": "roof_facade_indexs",
              "readableName": "Roof Facade Indexs",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "major_finding_image": {
            "x-rhino-attribute": {
              "name": "major_finding_image",
              "readableName": "Major Finding Image",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "user": {
            "x-rhino-attribute": {
              "name": "user",
              "readableName": "User",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/user"
              }
            ]
          },
          "currency": {
            "x-rhino-attribute": {
              "name": "currency",
              "readableName": "Currency",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/currency"
              }
            ]
          },
          "building": {
            "x-rhino-attribute": {
              "name": "building",
              "readableName": "Building",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building"
              }
            ]
          },
          "portal_control": {
            "x-rhino-attribute": {
              "name": "portal_control",
              "readableName": "Portal Control",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/portal_control"
              }
            ]
          },
          "building_code": {
            "x-rhino-attribute": {
              "name": "building_code",
              "readableName": "Building Code",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_code"
              }
            ]
          },
          "building_code_uvalues": {
            "x-rhino-attribute": {
              "name": "building_code_uvalues",
              "readableName": "Building Code Uvalues",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/building_code_uvalue"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "region_emission_factor": {
            "x-rhino-attribute": {
              "name": "region_emission_factor",
              "readableName": "Region Emission Factor",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/region_emission_factor"
              }
            ]
          },
          "city": {
            "x-rhino-attribute": {
              "name": "city",
              "readableName": "City",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/city"
              }
            ]
          },
          "facades": {
            "x-rhino-attribute": {
              "name": "facades",
              "readableName": "Facades",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/facade"
                }
              ],
              "x-rhino-attribute-array": {
                "creatable": false,
                "updatable": true,
                "destroyable": false
              }
            }
          },
          "zones": {
            "x-rhino-attribute": {
              "name": "zones",
              "readableName": "Zones",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/zone"
                }
              ],
              "x-rhino-attribute-array": {
                "creatable": false,
                "updatable": true,
                "destroyable": false
              }
            }
          },
          "default_ai_model": {
            "x-rhino-attribute": {
              "name": "default_ai_model",
              "readableName": "Default Ai Model",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/ai_model"
              }
            ]
          },
          "zone_maps": {
            "x-rhino-attribute": {
              "name": "zone_maps",
              "readableName": "Zone Maps",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/zone_map"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "custom_reports": {
            "x-rhino-attribute": {
              "name": "custom_reports",
              "readableName": "Custom Reports",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/custom_report"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "elements": {
            "x-rhino-attribute": {
              "name": "elements",
              "readableName": "Elements",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/element"
                }
              ],
              "x-rhino-attribute-array": {
                "creatable": true,
                "updatable": true,
                "destroyable": true
              }
            }
          },
          "zoning_file_attachment": {
            "x-rhino-attribute": {
              "name": "zoning_file_attachment",
              "readableName": "Zoning File Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "top_issue_three_d_model_attachment": {
            "x-rhino-attribute": {
              "name": "top_issue_three_d_model_attachment",
              "readableName": "Top Issue Three D Model Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "top_issue_three_d_cover_image_attachment": {
            "x-rhino-attribute": {
              "name": "top_issue_three_d_cover_image_attachment",
              "readableName": "Top Issue Three D Cover Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "operation_zoning_file_attachment": {
            "x-rhino-attribute": {
              "name": "operation_zoning_file_attachment",
              "readableName": "Operation Zoning File Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "default_visual_image_attachments": {
            "x-rhino-attribute": {
              "name": "default_visual_image_attachments",
              "readableName": "Default Visual Image Attachments",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "report_zoning_image_attachment": {
            "x-rhino-attribute": {
              "name": "report_zoning_image_attachment",
              "readableName": "Report Zoning Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "report_visual_image_attachment": {
            "x-rhino-attribute": {
              "name": "report_visual_image_attachment",
              "readableName": "Report Visual Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "report_major_finding_image_attachment": {
            "x-rhino-attribute": {
              "name": "report_major_finding_image_attachment",
              "readableName": "Report Major Finding Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "class_d_report_attachment": {
            "x-rhino-attribute": {
              "name": "class_d_report_attachment",
              "readableName": "Class D Report Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "assumption_summary_attachment": {
            "x-rhino-attribute": {
              "name": "assumption_summary_attachment",
              "readableName": "Assumption Summary Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "estimated_area_attachment": {
            "x-rhino-attribute": {
              "name": "estimated_area_attachment",
              "readableName": "Estimated Area Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "customer_logo_attachment": {
            "x-rhino-attribute": {
              "name": "customer_logo_attachment",
              "readableName": "Customer Logo Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ],
            "format": "image"
          },
          "qualitative_image_elements": {
            "x-rhino-attribute": {
              "name": "qualitative_image_elements",
              "readableName": "Qualitative Image Elements",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/qualitative_image_element"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "quantitative_image_elements": {
            "x-rhino-attribute": {
              "name": "quantitative_image_elements",
              "readableName": "Quantitative Image Elements",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/quantitative_image_element"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "cell_element_details": {
            "x-rhino-attribute": {
              "name": "cell_element_details",
              "readableName": "Cell Element Details",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "facade_element_details": {
            "x-rhino-attribute": {
              "name": "facade_element_details",
              "readableName": "Facade Element Details",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "energy_analysis_attachment": {
            "x-rhino-attribute": {
              "name": "energy_analysis_attachment",
              "readableName": "Energy Analysis Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "boxed_image_zip_attachment": {
            "x-rhino-attribute": {
              "name": "boxed_image_zip_attachment",
              "readableName": "Boxed Image Zip Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "ml_extract_attachment": {
            "x-rhino-attribute": {
              "name": "ml_extract_attachment",
              "readableName": "Ml Extract Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          },
          "ml_thermal_maps_attachments": {
            "x-rhino-attribute": {
              "name": "ml_thermal_maps_attachments",
              "readableName": "Ml Thermal Maps Attachments",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "ml_thermal_images_attachments": {
            "x-rhino-attribute": {
              "name": "ml_thermal_images_attachments",
              "readableName": "Ml Thermal Images Attachments",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "ml_images": {
            "x-rhino-attribute": {
              "name": "ml_images",
              "readableName": "Ml Images",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/ml_image"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "field_operations": {
            "x-rhino-attribute": {
              "name": "field_operations",
              "readableName": "Field Operations",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/field_operation"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "outside_temp",
          "humidity",
          "zone_count",
          "facade_count",
          "status_changed_at",
          "ekw_heating",
          "total_energy_consumption",
          "ekw_cooling",
          "currency",
          "building"
        ]
      },
      "building_image": {
        "x-rhino-model": {
          "model": "building_image",
          "modelPlural": "building_images",
          "name": "buildingImage",
          "pluralName": "buildingImages",
          "readableName": "Building image",
          "pluralReadableName": "Building images",
          "ownedBy": "building_operation",
          "singular": false,
          "path": "/api/building_images",
          "searchable": true
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "filename": {
            "x-rhino-attribute": {
              "name": "filename",
              "readableName": "Filename",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "taken_at": {
            "x-rhino-attribute": {
              "name": "taken_at",
              "readableName": "Taken At",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "longitude": {
            "x-rhino-attribute": {
              "name": "longitude",
              "readableName": "Longitude",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "latitude": {
            "x-rhino-attribute": {
              "name": "latitude",
              "readableName": "Latitude",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "selected": {
            "x-rhino-attribute": {
              "name": "selected",
              "readableName": "Selected",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": true,
            "type": "boolean"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "camera": {
            "x-rhino-attribute": {
              "name": "camera",
              "readableName": "Camera",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": "dji",
            "type": "string",
            "enum": [
              "dji",
              "flir"
            ]
          },
          "width": {
            "x-rhino-attribute": {
              "name": "width",
              "readableName": "Width",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "height": {
            "x-rhino-attribute": {
              "name": "height",
              "readableName": "Height",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "palette": {
            "x-rhino-attribute": {
              "name": "palette",
              "readableName": "Palette",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "enum": [
              "Rainbow",
              "Iron",
              "Arctic",
              "Gray",
              "Lava",
              "Rainbow_HC"
            ]
          },
          "scale_low": {
            "x-rhino-attribute": {
              "name": "scale_low",
              "readableName": "Scale Low",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "scale_high": {
            "x-rhino-attribute": {
              "name": "scale_high",
              "readableName": "Scale High",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_model": {
            "x-rhino-attribute": {
              "name": "camera_model",
              "readableName": "Camera Model",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "unknown",
            "type": "string",
            "enum": [
              "unknown",
              "zh20t",
              "mavic",
              "t530",
              "tz20r",
              "m30t"
            ]
          },
          "distance": {
            "x-rhino-attribute": {
              "name": "distance",
              "readableName": "Distance",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 10.0,
            "type": "float",
            "minimum": 0,
            "exclusiveMinimum": true
          },
          "original_key": {
            "x-rhino-attribute": {
              "name": "original_key",
              "readableName": "Original Key",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "rotation": {
            "x-rhino-attribute": {
              "name": "rotation",
              "readableName": "Rotation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 0,
            "type": "integer"
          },
          "inside_temp": {
            "x-rhino-attribute": {
              "name": "inside_temp",
              "readableName": "Inside Temp (°C)",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "is_major_qualitative": {
            "x-rhino-attribute": {
              "name": "is_major_qualitative",
              "readableName": "Is Major Qualitative",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "lens": {
            "x-rhino-attribute": {
              "name": "lens",
              "readableName": "Lens",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 10,
            "type": "string",
            "enum": [
              10,
              17,
              29
            ]
          },
          "is_custom_distance": {
            "x-rhino-attribute": {
              "name": "is_custom_distance",
              "readableName": "Is Custom Distance",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "cell": {
            "x-rhino-attribute": {
              "name": "cell",
              "readableName": "Cell",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/cell"
              }
            ]
          },
          "building_operation": {
            "x-rhino-attribute": {
              "name": "building_operation",
              "readableName": "Building Operation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_operation"
              }
            ]
          },
          "facade": {
            "x-rhino-attribute": {
              "name": "facade",
              "readableName": "Facade",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade"
              }
            ]
          },
          "facade_element_reflectives": {
            "x-rhino-attribute": {
              "name": "facade_element_reflectives",
              "readableName": "Facade Element Reflectives",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "thermal_image_attachment": {
            "x-rhino-attribute": {
              "name": "thermal_image_attachment",
              "readableName": "Thermal Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ],
            "format": "image"
          },
          "working_image_attachment": {
            "x-rhino-attribute": {
              "name": "working_image_attachment",
              "readableName": "Working Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ],
            "format": "image"
          },
          "visual_image_attachment": {
            "x-rhino-attribute": {
              "name": "visual_image_attachment",
              "readableName": "Visual Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ],
            "format": "image"
          },
          "boxed_image_attachment": {
            "x-rhino-attribute": {
              "name": "boxed_image_attachment",
              "readableName": "Boxed Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ],
            "format": "image"
          },
          "quantitative_boxed_images": {
            "x-rhino-attribute": {
              "name": "quantitative_boxed_images",
              "readableName": "Quantitative Boxed Images",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/quantitative_boxed_image"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "cell_element_details": {
            "x-rhino-attribute": {
              "name": "cell_element_details",
              "readableName": "Cell Element Details",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "quantitative_image_elements": {
            "x-rhino-attribute": {
              "name": "quantitative_image_elements",
              "readableName": "Quantitative Image Elements",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/quantitative_image_element"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "qualitative_image_elements": {
            "x-rhino-attribute": {
              "name": "qualitative_image_elements",
              "readableName": "Qualitative Image Elements",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/qualitative_image_element"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          },
          "facade_index": {
            "x-rhino-attribute": {
              "name": "facade_index",
              "readableName": "Facade Index",
              "readable": false,
              "creatable": true,
              "updatable": true
            },
            "writeOnly": true,
            "nullable": true,
            "type": "integer",
            "format": "customn_select"
          },
          "riser_index": {
            "x-rhino-attribute": {
              "name": "riser_index",
              "readableName": "Riser Index",
              "readable": false,
              "creatable": true,
              "updatable": true
            },
            "writeOnly": true,
            "nullable": true,
            "type": "integer"
          },
          "level_index": {
            "x-rhino-attribute": {
              "name": "level_index",
              "readableName": "Level Index",
              "readable": false,
              "creatable": true,
              "updatable": true
            },
            "writeOnly": true,
            "nullable": true,
            "type": "integer"
          }
        },
        "required": [
          "filename",
          "taken_at",
          "selected",
          "camera",
          "distance",
          "original_key",
          "rotation",
          "building_operation",
          "facade"
        ]
      },
      "quantitative_image_element": {
        "x-rhino-model": {
          "model": "quantitative_image_element",
          "modelPlural": "quantitative_image_elements",
          "name": "quantitativeImageElement",
          "pluralName": "quantitativeImageElements",
          "readableName": "Quantitative image element",
          "pluralReadableName": "Quantitative image elements",
          "ownedBy": "building_image",
          "singular": false,
          "path": "/api/quantitative_image_elements",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "top": {
            "x-rhino-attribute": {
              "name": "top",
              "readableName": "Top",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0
          },
          "left": {
            "x-rhino-attribute": {
              "name": "left",
              "readableName": "Left",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0
          },
          "width": {
            "x-rhino-attribute": {
              "name": "width",
              "readableName": "Width",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true
          },
          "height": {
            "x-rhino-attribute": {
              "name": "height",
              "readableName": "Height",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true
          },
          "avg_temp": {
            "x-rhino-attribute": {
              "name": "avg_temp",
              "readableName": "Avg Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "u_value": {
            "x-rhino-attribute": {
              "name": "u_value",
              "readableName": "U Value",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "min_temp": {
            "x-rhino-attribute": {
              "name": "min_temp",
              "readableName": "Min Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "max_temp": {
            "x-rhino-attribute": {
              "name": "max_temp",
              "readableName": "Max Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "min_temp_x": {
            "x-rhino-attribute": {
              "name": "min_temp_x",
              "readableName": "Min Temp X",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0.0
          },
          "min_temp_y": {
            "x-rhino-attribute": {
              "name": "min_temp_y",
              "readableName": "Min Temp Y",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0.0
          },
          "max_temp_x": {
            "x-rhino-attribute": {
              "name": "max_temp_x",
              "readableName": "Max Temp X",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0.0
          },
          "max_temp_y": {
            "x-rhino-attribute": {
              "name": "max_temp_y",
              "readableName": "Max Temp Y",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0.0
          },
          "u_value_out_of_range": {
            "x-rhino-attribute": {
              "name": "u_value_out_of_range",
              "readableName": "U Value Out Of Range",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "formula_version": {
            "x-rhino-attribute": {
              "name": "formula_version",
              "readableName": "Formula Version",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "advanced",
            "type": "string",
            "enum": [
              "standard",
              "advanced"
            ]
          },
          "slope": {
            "x-rhino-attribute": {
              "name": "slope",
              "readableName": "Slope",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "intercept_point": {
            "x-rhino-attribute": {
              "name": "intercept_point",
              "readableName": "Intercept Point",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "angle": {
            "x-rhino-attribute": {
              "name": "angle",
              "readableName": "Angle",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0,
            "type": "integer"
          },
          "element_name": {
            "x-rhino-attribute": {
              "name": "element_name",
              "readableName": "Element Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true
          },
          "cell_element_detail": {
            "x-rhino-attribute": {
              "name": "cell_element_detail",
              "readableName": "Cell Element Detail",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/cell_element_detail"
              }
            ]
          },
          "building_image": {
            "x-rhino-attribute": {
              "name": "building_image",
              "readableName": "Building Image",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_image"
              }
            ]
          },
          "element": {
            "x-rhino-attribute": {
              "name": "element",
              "readableName": "Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/element"
              }
            ]
          },
          "facade": {
            "x-rhino-attribute": {
              "name": "facade",
              "readableName": "Facade",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade"
              }
            ]
          },
          "facade_element_detail": {
            "x-rhino-attribute": {
              "name": "facade_element_detail",
              "readableName": "Facade Element Detail",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade_element_detail"
              }
            ]
          },
          "facade_element_reflectives": {
            "x-rhino-attribute": {
              "name": "facade_element_reflectives",
              "readableName": "Facade Element Reflectives",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "top",
          "left",
          "width",
          "height",
          "min_temp_x",
          "min_temp_y",
          "max_temp_x",
          "max_temp_y",
          "cell_element_detail",
          "building_image"
        ]
      },
      "qualitative_image_element": {
        "x-rhino-model": {
          "model": "qualitative_image_element",
          "modelPlural": "qualitative_image_elements",
          "name": "qualitativeImageElement",
          "pluralName": "qualitativeImageElements",
          "readableName": "Qualitative image element",
          "pluralReadableName": "Qualitative image elements",
          "ownedBy": "building_image",
          "singular": false,
          "path": "/api/qualitative_image_elements",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "top": {
            "x-rhino-attribute": {
              "name": "top",
              "readableName": "Top",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0
          },
          "left": {
            "x-rhino-attribute": {
              "name": "left",
              "readableName": "Left",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0
          },
          "width": {
            "x-rhino-attribute": {
              "name": "width",
              "readableName": "Width",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true
          },
          "height": {
            "x-rhino-attribute": {
              "name": "height",
              "readableName": "Height",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0,
            "exclusiveMinimum": true
          },
          "avg_temp": {
            "x-rhino-attribute": {
              "name": "avg_temp",
              "readableName": "Avg Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "rating": {
            "x-rhino-attribute": {
              "name": "rating",
              "readableName": "Rating",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "monitor",
            "type": "string",
            "enum": [
              "monitor",
              "investigate",
              "maintenance"
            ]
          },
          "u_value": {
            "x-rhino-attribute": {
              "name": "u_value",
              "readableName": "U Value",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "min_temp": {
            "x-rhino-attribute": {
              "name": "min_temp",
              "readableName": "Min Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "max_temp": {
            "x-rhino-attribute": {
              "name": "max_temp",
              "readableName": "Max Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "min_temp_x": {
            "x-rhino-attribute": {
              "name": "min_temp_x",
              "readableName": "Min Temp X",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0.0
          },
          "min_temp_y": {
            "x-rhino-attribute": {
              "name": "min_temp_y",
              "readableName": "Min Temp Y",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0.0
          },
          "max_temp_x": {
            "x-rhino-attribute": {
              "name": "max_temp_x",
              "readableName": "Max Temp X",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0.0
          },
          "max_temp_y": {
            "x-rhino-attribute": {
              "name": "max_temp_y",
              "readableName": "Max Temp Y",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "integer",
            "minimum": 0.0
          },
          "aoi_algorithm": {
            "x-rhino-attribute": {
              "name": "aoi_algorithm",
              "readableName": "Aoi Algorithm",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "none",
            "type": "string",
            "enum": [
              "none",
              "temp_range",
              "pixel_walk"
            ]
          },
          "aoi_min_temp": {
            "x-rhino-attribute": {
              "name": "aoi_min_temp",
              "readableName": "Aoi Min Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "aoi_max_temp": {
            "x-rhino-attribute": {
              "name": "aoi_max_temp",
              "readableName": "Aoi Max Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": "aoi_min_temp",
            "exclusiveMinimum": true
          },
          "aoi_step_max": {
            "x-rhino-attribute": {
              "name": "aoi_step_max",
              "readableName": "Aoi Step Max",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "aoi_walk_max": {
            "x-rhino-attribute": {
              "name": "aoi_walk_max",
              "readableName": "Aoi Walk Max",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "aoi_u_value": {
            "x-rhino-attribute": {
              "name": "aoi_u_value",
              "readableName": "Aoi U Value",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "aoi_energy_loss_area": {
            "x-rhino-attribute": {
              "name": "aoi_energy_loss_area",
              "readableName": "Aoi Energy Loss Area",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "aoi_energy_loss": {
            "x-rhino-attribute": {
              "name": "aoi_energy_loss",
              "readableName": "Aoi Energy Loss",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "is_top_issue": {
            "x-rhino-attribute": {
              "name": "is_top_issue",
              "readableName": "Is Top Issue",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "u_value_out_of_range": {
            "x-rhino-attribute": {
              "name": "u_value_out_of_range",
              "readableName": "U Value Out Of Range",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "formula_version": {
            "x-rhino-attribute": {
              "name": "formula_version",
              "readableName": "Formula Version",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "advanced",
            "type": "string",
            "enum": [
              "standard",
              "advanced"
            ]
          },
          "slope": {
            "x-rhino-attribute": {
              "name": "slope",
              "readableName": "Slope",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "intercept_point": {
            "x-rhino-attribute": {
              "name": "intercept_point",
              "readableName": "Intercept Point",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "angle": {
            "x-rhino-attribute": {
              "name": "angle",
              "readableName": "Angle",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0,
            "type": "integer"
          },
          "note": {
            "x-rhino-attribute": {
              "name": "note",
              "readableName": "Note",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/note"
              }
            ]
          },
          "cell_element_detail": {
            "x-rhino-attribute": {
              "name": "cell_element_detail",
              "readableName": "Cell Element Detail",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/cell_element_detail"
              }
            ]
          },
          "building_image": {
            "x-rhino-attribute": {
              "name": "building_image",
              "readableName": "Building Image",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_image"
              }
            ]
          },
          "element": {
            "x-rhino-attribute": {
              "name": "element",
              "readableName": "Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/element"
              }
            ]
          },
          "facade": {
            "x-rhino-attribute": {
              "name": "facade",
              "readableName": "Facade",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade"
              }
            ]
          },
          "facade_element_detail": {
            "x-rhino-attribute": {
              "name": "facade_element_detail",
              "readableName": "Facade Element Detail",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/facade_element_detail"
              }
            ]
          },
          "facade_element_reflectives": {
            "x-rhino-attribute": {
              "name": "facade_element_reflectives",
              "readableName": "Facade Element Reflectives",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "top",
          "left",
          "width",
          "height",
          "min_temp_x",
          "min_temp_y",
          "max_temp_x",
          "max_temp_y",
          "aoi_max_temp",
          "cell_element_detail",
          "building_image"
        ]
      },
      "base_element": {
        "x-rhino-model": {
          "model": "base_element",
          "modelPlural": "base_elements",
          "name": "baseElement",
          "pluralName": "baseElements",
          "readableName": "Base element",
          "pluralReadableName": "Base elements",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/base_elements",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "emissivity": {
            "x-rhino-attribute": {
              "name": "emissivity",
              "readableName": "Emissivity",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 0.1,
            "maximum": 1.0
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "reportable": {
            "x-rhino-attribute": {
              "name": "reportable",
              "readableName": "Reportable",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": true,
            "type": "boolean"
          },
          "order_by": {
            "x-rhino-attribute": {
              "name": "order_by",
              "readableName": "Order By",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          }
        },
        "required": [
          "name",
          "emissivity"
        ]
      },
      "element_library": {
        "x-rhino-model": {
          "model": "element_library",
          "modelPlural": "element_libraries",
          "name": "elementLibrary",
          "pluralName": "elementLibraries",
          "readableName": "Element library",
          "pluralReadableName": "Element libraries",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/element_libraries",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "emissivity": {
            "x-rhino-attribute": {
              "name": "emissivity",
              "readableName": "Emissivity",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 0.1,
            "maximum": 1.0
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "base_element": {
            "x-rhino-attribute": {
              "name": "base_element",
              "readableName": "Base Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/base_element"
              }
            ]
          }
        },
        "required": [
          "emissivity",
          "base_element"
        ]
      },
      "element": {
        "x-rhino-model": {
          "model": "element",
          "modelPlural": "elements",
          "name": "element",
          "pluralName": "elements",
          "readableName": "Element",
          "pluralReadableName": "Elements",
          "ownedBy": "building_operation",
          "singular": false,
          "path": "/api/elements",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "emissivity": {
            "x-rhino-attribute": {
              "name": "emissivity",
              "readableName": "Emissivity",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 0.1,
            "maximum": 1.0
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "r_value": {
            "x-rhino-attribute": {
              "name": "r_value",
              "readableName": "R Value",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0.0,
            "type": "float"
          },
          "u_value": {
            "x-rhino-attribute": {
              "name": "u_value",
              "readableName": "U Value",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0.0,
            "type": "float"
          },
          "outside_design_temp": {
            "x-rhino-attribute": {
              "name": "outside_design_temp",
              "readableName": "Outside Design Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "u_value_max": {
            "x-rhino-attribute": {
              "name": "u_value_max",
              "readableName": "U Value Max",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "outside_max_temp": {
            "x-rhino-attribute": {
              "name": "outside_max_temp",
              "readableName": "Outside Max Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "r_value_i": {
            "x-rhino-attribute": {
              "name": "r_value_i",
              "readableName": "Rᵢ",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 0.12,
            "type": "float"
          },
          "r_value_o": {
            "x-rhino-attribute": {
              "name": "r_value_o",
              "readableName": "Rₒ",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 0.05,
            "type": "float"
          },
          "r_min_i": {
            "x-rhino-attribute": {
              "name": "r_min_i",
              "readableName": "Rᵢᵐⁱⁿ",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 0.1,
            "type": "float"
          },
          "r_min_o": {
            "x-rhino-attribute": {
              "name": "r_min_o",
              "readableName": "Rₒᵐⁱⁿ",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 0.025,
            "type": "float"
          },
          "building_operation": {
            "x-rhino-attribute": {
              "name": "building_operation",
              "readableName": "Building Operation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_operation"
              }
            ]
          },
          "base_element": {
            "x-rhino-attribute": {
              "name": "base_element",
              "readableName": "Base Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/base_element"
              }
            ]
          },
          "iot_files": {
            "x-rhino-attribute": {
              "name": "iot_files",
              "readableName": "Iot Files",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/iot_file"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "name",
          "emissivity",
          "r_value_i",
          "r_value_o",
          "r_min_i",
          "r_min_o",
          "building_operation",
          "base_element"
        ]
      },
      "note_category": {
        "x-rhino-model": {
          "model": "note_category",
          "modelPlural": "note_categories",
          "name": "noteCategory",
          "pluralName": "noteCategories",
          "readableName": "Note category",
          "pluralReadableName": "Note categories",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/note_categories",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "is_legacy": {
            "x-rhino-attribute": {
              "name": "is_legacy",
              "readableName": "Is Legacy",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": true,
            "type": "boolean"
          },
          "category_type": {
            "x-rhino-attribute": {
              "name": "category_type",
              "readableName": "Category Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "legacy",
            "type": "string",
            "enum": [
              "legacy",
              "other",
              "structural",
              "energy_loss"
            ]
          },
          "base_element": {
            "x-rhino-attribute": {
              "name": "base_element",
              "readableName": "Base Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/base_element"
              }
            ]
          }
        },
        "required": [
          "name"
        ]
      },
      "note": {
        "x-rhino-model": {
          "model": "note",
          "modelPlural": "notes",
          "name": "note",
          "pluralName": "notes",
          "readableName": "Note",
          "pluralReadableName": "Notes",
          "ownedBy": "note_category",
          "singular": false,
          "path": "/api/notes",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "description": {
            "x-rhino-attribute": {
              "name": "description",
              "readableName": "Description",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "text"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "summarizable": {
            "x-rhino-attribute": {
              "name": "summarizable",
              "readableName": "Summarizable",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": true,
            "type": "boolean"
          },
          "rating_type": {
            "x-rhino-attribute": {
              "name": "rating_type",
              "readableName": "Rating Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "none",
            "type": "string",
            "enum": [
              "none",
              "note_base",
              "delta_t"
            ]
          },
          "recommendation": {
            "x-rhino-attribute": {
              "name": "recommendation",
              "readableName": "Recommendation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "is_legacy": {
            "x-rhino-attribute": {
              "name": "is_legacy",
              "readableName": "Is Legacy",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": true,
            "type": "boolean"
          },
          "new_note_map": {
            "x-rhino-attribute": {
              "name": "new_note_map",
              "readableName": "New Note Map",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "note_category": {
            "x-rhino-attribute": {
              "name": "note_category",
              "readableName": "Note Category",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/note_category"
              }
            ]
          }
        },
        "required": [
          "name",
          "description",
          "note_category"
        ]
      },
      "report_set": {
        "x-rhino-model": {
          "model": "report_set",
          "modelPlural": "report_sets",
          "name": "reportSet",
          "pluralName": "reportSets",
          "readableName": "Report set",
          "pluralReadableName": "Report sets",
          "ownedBy": "building_operation",
          "singular": false,
          "path": "/api/report_sets",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "description": {
            "x-rhino-attribute": {
              "name": "description",
              "readableName": "Description",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "approved": {
            "x-rhino-attribute": {
              "name": "approved",
              "readableName": "Approved",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": false,
            "type": "boolean"
          },
          "is_show_in_portal": {
            "x-rhino-attribute": {
              "name": "is_show_in_portal",
              "readableName": "Is Show In Portal",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "annualization_id": {
            "x-rhino-attribute": {
              "name": "annualization_id",
              "readableName": "Annualization",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "current_unit": {
            "x-rhino-attribute": {
              "name": "current_unit",
              "readableName": "Current Unit",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "report_template": {
            "x-rhino-attribute": {
              "name": "report_template",
              "readableName": "Report Template",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "thermal_transmittance_mode": {
            "x-rhino-attribute": {
              "name": "thermal_transmittance_mode",
              "readableName": "Thermal Transmittance Mode",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "report_locale": {
            "x-rhino-attribute": {
              "name": "report_locale",
              "readableName": "Report Locale",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": "en",
            "type": "string"
          },
          "building_operation": {
            "x-rhino-attribute": {
              "name": "building_operation",
              "readableName": "Building Operation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_operation"
              }
            ]
          },
          "reports": {
            "x-rhino-attribute": {
              "name": "reports",
              "readableName": "Reports",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/report"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "approved",
          "report_locale",
          "building_operation"
        ]
      },
      "report": {
        "x-rhino-model": {
          "model": "report",
          "modelPlural": "reports",
          "name": "report",
          "pluralName": "reports",
          "readableName": "Report",
          "pluralReadableName": "Reports",
          "ownedBy": "report_set",
          "singular": false,
          "path": "/api/reports",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "reportable_type": {
            "x-rhino-attribute": {
              "name": "reportable_type",
              "readableName": "Reportable Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "report_type": {
            "x-rhino-attribute": {
              "name": "report_type",
              "readableName": "Report Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string",
            "enum": [
              "quantitative_facade",
              "qualitative_cell",
              "executive",
              "complete",
              "quantitative",
              "qualitative",
              "teaser_report",
              "notes_glossary",
              "executive_facade",
              "class_d",
              "top_issues"
            ]
          },
          "url_attachment": {
            "x-rhino-attribute": {
              "name": "url_attachment",
              "readableName": "Url Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": "",
            "type": "string"
          },
          "report_set": {
            "x-rhino-attribute": {
              "name": "report_set",
              "readableName": "Report Set",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/report_set"
              }
            ]
          },
          "report_attachment": {
            "x-rhino-attribute": {
              "name": "report_attachment",
              "readableName": "Report Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ]
          }
        },
        "required": [
          "reportable_type",
          "report_type",
          "report_set"
        ]
      },
      "city": {
        "x-rhino-model": {
          "model": "city",
          "modelPlural": "cities",
          "name": "city",
          "pluralName": "cities",
          "readableName": "City",
          "pluralReadableName": "Cities",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/cities",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          }
        },
        "required": [
          "name"
        ]
      },
      "customer_site": {
        "x-rhino-model": {
          "model": "customer_site",
          "modelPlural": "customer_sites",
          "name": "customerSite",
          "pluralName": "customerSites",
          "readableName": "Customer site",
          "pluralReadableName": "Customer sites",
          "ownedBy": "organization",
          "singular": false,
          "path": "/api/customer_sites",
          "searchable": true
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "external_id": {
            "x-rhino-attribute": {
              "name": "external_id",
              "readableName": "External",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "organization": {
            "x-rhino-attribute": {
              "name": "organization",
              "readableName": "Organization",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/organization"
              }
            ]
          },
          "customer": {
            "x-rhino-attribute": {
              "name": "customer",
              "readableName": "Customer",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/organization"
              }
            ]
          }
        },
        "required": [
          "organization",
          "customer"
        ]
      },
      "customer_building": {
        "x-rhino-model": {
          "model": "customer_building",
          "modelPlural": "customer_buildings",
          "name": "customerBuilding",
          "pluralName": "customerBuildings",
          "readableName": "Customer building",
          "pluralReadableName": "Customer buildings",
          "ownedBy": "customer_site",
          "singular": false,
          "path": "/api/customer_buildings",
          "searchable": true
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "address": {
            "x-rhino-attribute": {
              "name": "address",
              "readableName": "Address",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "city": {
            "x-rhino-attribute": {
              "name": "city",
              "readableName": "City",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "state": {
            "x-rhino-attribute": {
              "name": "state",
              "readableName": "State",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "country": {
            "x-rhino-attribute": {
              "name": "country",
              "readableName": "Country",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "format": "country"
          },
          "latitude": {
            "x-rhino-attribute": {
              "name": "latitude",
              "readableName": "Latitude",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "longitude": {
            "x-rhino-attribute": {
              "name": "longitude",
              "readableName": "Longitude",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "building_type_id": {
            "x-rhino-attribute": {
              "name": "building_type_id",
              "readableName": "Building Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "expiry_date": {
            "x-rhino-attribute": {
              "name": "expiry_date",
              "readableName": "Expiry Date",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "format": "datetime"
          },
          "external_id": {
            "x-rhino-attribute": {
              "name": "external_id",
              "readableName": "External",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "searchable_en": {
            "x-rhino-attribute": {
              "name": "searchable_en",
              "readableName": "Searchable En",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "tsvector"
          },
          "created_by_id": {
            "x-rhino-attribute": {
              "name": "created_by_id",
              "readableName": "Created By",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "updated_by_id": {
            "x-rhino-attribute": {
              "name": "updated_by_id",
              "readableName": "Updated By",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "customer_site": {
            "x-rhino-attribute": {
              "name": "customer_site",
              "readableName": "Customer Site",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/customer_site"
              }
            ]
          },
          "building_operations": {
            "x-rhino-attribute": {
              "name": "building_operations",
              "readableName": "Building Operations",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/building_operation"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "customer_site"
        ]
      },
      "customer_building_operation": {
        "x-rhino-model": {
          "model": "customer_building_operation",
          "modelPlural": "customer_building_operations",
          "name": "customerBuildingOperation",
          "pluralName": "customerBuildingOperations",
          "readableName": "Customer building operation",
          "pluralReadableName": "Customer building operations",
          "ownedBy": "customer_building",
          "singular": false,
          "path": "/api/customer_building_operations",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "operation_at": {
            "x-rhino-attribute": {
              "name": "operation_at",
              "readableName": "Operation At",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "format": "datetime"
          },
          "outside_temp": {
            "x-rhino-attribute": {
              "name": "outside_temp",
              "readableName": "Outside Temp",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "humidity": {
            "x-rhino-attribute": {
              "name": "humidity",
              "readableName": "Humidity",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "operation_notes": {
            "x-rhino-attribute": {
              "name": "operation_notes",
              "readableName": "Operation Notes",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "zone_count": {
            "x-rhino-attribute": {
              "name": "zone_count",
              "readableName": "Zone Count",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "facade_count": {
            "x-rhino-attribute": {
              "name": "facade_count",
              "readableName": "Facade Count",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "status": {
            "x-rhino-attribute": {
              "name": "status",
              "readableName": "Status",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 10,
            "type": "integer"
          },
          "status_changed_at": {
            "x-rhino-attribute": {
              "name": "status_changed_at",
              "readableName": "Status Changed At",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "ekw_heating": {
            "x-rhino-attribute": {
              "name": "ekw_heating",
              "readableName": "Ekw Heating",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "building_code_id": {
            "x-rhino-attribute": {
              "name": "building_code_id",
              "readableName": "Building Code",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "city_id": {
            "x-rhino-attribute": {
              "name": "city_id",
              "readableName": "City",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "report_summary": {
            "x-rhino-attribute": {
              "name": "report_summary",
              "readableName": "Report Summary",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "total_energy_consumption": {
            "x-rhino-attribute": {
              "name": "total_energy_consumption",
              "readableName": "Total Energy Consumption (MWh)",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "visual_operation_at": {
            "x-rhino-attribute": {
              "name": "visual_operation_at",
              "readableName": "Visual Operation At",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "format": "datetime"
          },
          "report_completion_days": {
            "x-rhino-attribute": {
              "name": "report_completion_days",
              "readableName": "Report Completion Days",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 1,
            "type": "integer"
          },
          "report_data_points": {
            "x-rhino-attribute": {
              "name": "report_data_points",
              "readableName": "Report Data Points",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "report_thermal_duration_hours": {
            "x-rhino-attribute": {
              "name": "report_thermal_duration_hours",
              "readableName": "Report Thermal Duration Hours",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "report_visual_duration_hours": {
            "x-rhino-attribute": {
              "name": "report_visual_duration_hours",
              "readableName": "Report Visual Duration Hours",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "ekw_cooling": {
            "x-rhino-attribute": {
              "name": "ekw_cooling",
              "readableName": "Ekw Cooling",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 0.1,
            "type": "float"
          },
          "user_id": {
            "x-rhino-attribute": {
              "name": "user_id",
              "readableName": "User",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "external_id": {
            "x-rhino-attribute": {
              "name": "external_id",
              "readableName": "External",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "nira_model_path": {
            "x-rhino-attribute": {
              "name": "nira_model_path",
              "readableName": "Nira Model Path",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "default_formula_version": {
            "x-rhino-attribute": {
              "name": "default_formula_version",
              "readableName": "Default Formula Version",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 2,
            "type": "integer"
          },
          "region_emission_factor_id": {
            "x-rhino-attribute": {
              "name": "region_emission_factor_id",
              "readableName": "Region Emission Factor",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "emission_snapshot_id": {
            "x-rhino-attribute": {
              "name": "emission_snapshot_id",
              "readableName": "Emission Snapshot",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "total_emission": {
            "x-rhino-attribute": {
              "name": "total_emission",
              "readableName": "Total Emission",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "currency_id": {
            "x-rhino-attribute": {
              "name": "currency_id",
              "readableName": "Currency",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": 1,
            "type": "integer"
          },
          "heating_ratio": {
            "x-rhino-attribute": {
              "name": "heating_ratio",
              "readableName": "Heating Ratio",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "cooling_ratio": {
            "x-rhino-attribute": {
              "name": "cooling_ratio",
              "readableName": "Cooling Ratio",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "is_ml_project": {
            "x-rhino-attribute": {
              "name": "is_ml_project",
              "readableName": "Is Ml Project",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "default_ai_model_id": {
            "x-rhino-attribute": {
              "name": "default_ai_model_id",
              "readableName": "Default Ai Model",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "customer_building": {
            "x-rhino-attribute": {
              "name": "customer_building",
              "readableName": "Customer Building",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/customer_building"
              }
            ]
          }
        },
        "required": [
          "status_changed_at",
          "currency_id",
          "customer_building"
        ]
      },
      "customer_report_set": {
        "x-rhino-model": {
          "model": "customer_report_set",
          "modelPlural": "customer_report_sets",
          "name": "customerReportSet",
          "pluralName": "customerReportSets",
          "readableName": "Customer report set",
          "pluralReadableName": "Customer report sets",
          "ownedBy": "customer_building_operation",
          "singular": false,
          "path": "/api/customer_report_sets",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "description": {
            "x-rhino-attribute": {
              "name": "description",
              "readableName": "Description",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "approved": {
            "x-rhino-attribute": {
              "name": "approved",
              "readableName": "Approved",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": false,
            "type": "boolean"
          },
          "is_show_in_portal": {
            "x-rhino-attribute": {
              "name": "is_show_in_portal",
              "readableName": "Is Show In Portal",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "annualization_id": {
            "x-rhino-attribute": {
              "name": "annualization_id",
              "readableName": "Annualization",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "current_unit": {
            "x-rhino-attribute": {
              "name": "current_unit",
              "readableName": "Current Unit",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "report_template": {
            "x-rhino-attribute": {
              "name": "report_template",
              "readableName": "Report Template",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "thermal_transmittance_mode": {
            "x-rhino-attribute": {
              "name": "thermal_transmittance_mode",
              "readableName": "Thermal Transmittance Mode",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_by_id": {
            "x-rhino-attribute": {
              "name": "created_by_id",
              "readableName": "Created By",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "updated_by_id": {
            "x-rhino-attribute": {
              "name": "updated_by_id",
              "readableName": "Updated By",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "report_locale": {
            "x-rhino-attribute": {
              "name": "report_locale",
              "readableName": "Report Locale",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "default": "en",
            "type": "string"
          },
          "customer_building_operation": {
            "x-rhino-attribute": {
              "name": "customer_building_operation",
              "readableName": "Customer Building Operation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/customer_building_operation"
              }
            ]
          },
          "reports": {
            "x-rhino-attribute": {
              "name": "reports",
              "readableName": "Reports",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/report"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "approved",
          "report_locale",
          "customer_building_operation"
        ]
      },
      "issue": {
        "x-rhino-model": {
          "model": "issue",
          "modelPlural": "issues",
          "name": "issue",
          "pluralName": "issues",
          "readableName": "Issue",
          "pluralReadableName": "Issues",
          "ownedBy": "building_operation",
          "singular": false,
          "path": "/api/issues",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "order": {
            "x-rhino-attribute": {
              "name": "order",
              "readableName": "Order",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "x_coordinate": {
            "x-rhino-attribute": {
              "name": "x_coordinate",
              "readableName": "X Coordinate",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "y_coordinate": {
            "x-rhino-attribute": {
              "name": "y_coordinate",
              "readableName": "Y Coordinate",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "z_coordinate": {
            "x-rhino-attribute": {
              "name": "z_coordinate",
              "readableName": "Z Coordinate",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "description": {
            "x-rhino-attribute": {
              "name": "description",
              "readableName": "Description",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "recommendation": {
            "x-rhino-attribute": {
              "name": "recommendation",
              "readableName": "Recommendation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "text"
          },
          "camera_x": {
            "x-rhino-attribute": {
              "name": "camera_x",
              "readableName": "Camera X",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_y": {
            "x-rhino-attribute": {
              "name": "camera_y",
              "readableName": "Camera Y",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_z": {
            "x-rhino-attribute": {
              "name": "camera_z",
              "readableName": "Camera Z",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_rotation_x": {
            "x-rhino-attribute": {
              "name": "camera_rotation_x",
              "readableName": "Camera Rotation X",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_rotation_y": {
            "x-rhino-attribute": {
              "name": "camera_rotation_y",
              "readableName": "Camera Rotation Y",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_rotation_z": {
            "x-rhino-attribute": {
              "name": "camera_rotation_z",
              "readableName": "Camera Rotation Z",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_fov": {
            "x-rhino-attribute": {
              "name": "camera_fov",
              "readableName": "Camera Fov",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_up_x": {
            "x-rhino-attribute": {
              "name": "camera_up_x",
              "readableName": "Camera Up X",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_up_y": {
            "x-rhino-attribute": {
              "name": "camera_up_y",
              "readableName": "Camera Up Y",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "camera_up_z": {
            "x-rhino-attribute": {
              "name": "camera_up_z",
              "readableName": "Camera Up Z",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "float"
          },
          "facade_index": {
            "x-rhino-attribute": {
              "name": "facade_index",
              "readableName": "Facade Index",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer",
            "format": "customn_select"
          },
          "riser_index": {
            "x-rhino-attribute": {
              "name": "riser_index",
              "readableName": "Riser Index",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "level_index": {
            "x-rhino-attribute": {
              "name": "level_index",
              "readableName": "Level Index",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "integer"
          },
          "cell": {
            "x-rhino-attribute": {
              "name": "cell",
              "readableName": "Cell",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/cell"
              }
            ]
          },
          "building_operation": {
            "x-rhino-attribute": {
              "name": "building_operation",
              "readableName": "Building Operation",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/building_operation"
              }
            ]
          },
          "qualitative_image_element": {
            "x-rhino-attribute": {
              "name": "qualitative_image_element",
              "readableName": "Qualitative Image Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/qualitative_image_element"
              }
            ]
          },
          "image_attachment": {
            "x-rhino-attribute": {
              "name": "image_attachment",
              "readableName": "Image Attachment",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/active_storage_attachment"
              }
            ],
            "format": "image"
          }
        },
        "required": [
          "building_operation",
          "qualitative_image_element"
        ]
      },
      "fuel_emission_factor": {
        "x-rhino-model": {
          "model": "fuel_emission_factor",
          "modelPlural": "fuel_emission_factors",
          "name": "fuelEmissionFactor",
          "pluralName": "fuelEmissionFactors",
          "readableName": "Fuel emission factor",
          "pluralReadableName": "Fuel emission factors",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/fuel_emission_factors",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "fuel_source": {
            "x-rhino-attribute": {
              "name": "fuel_source",
              "readableName": "Fuel Source",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string",
            "enum": [
              "electricity",
              "natural_gas",
              "propane",
              "diesel_oil",
              "coal_anthracite",
              "coal_bituminous",
              "coke",
              "fuel_oil_1",
              "fuel_oil_2",
              "fuel_oil_4",
              "fuel_oil_5_6",
              "wood",
              "kerosene",
              "green",
              "direct_steam",
              "chilled_water_eletric",
              "chilled_water_absorption",
              "chilled_water_engine",
              "hot_water"
            ]
          },
          "fuel_type": {
            "x-rhino-attribute": {
              "name": "fuel_type",
              "readableName": "Fuel Type",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string",
            "enum": [
              "direct",
              "passive",
              "biomass",
              "green"
            ]
          },
          "emission_factor": {
            "x-rhino-attribute": {
              "name": "emission_factor",
              "readableName": "Emission Factor",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 1.0,
            "type": "float"
          },
          "display_name": {
            "x-rhino-attribute": {
              "name": "display_name",
              "readableName": "Display Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "year": {
            "x-rhino-attribute": {
              "name": "year",
              "readableName": "Year",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "region_emission_factor": {
            "x-rhino-attribute": {
              "name": "region_emission_factor",
              "readableName": "Region Emission Factor",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/region_emission_factor"
              }
            ]
          }
        },
        "required": [
          "fuel_source",
          "fuel_type",
          "region_emission_factor"
        ]
      },
      "region_emission_factor": {
        "x-rhino-model": {
          "model": "region_emission_factor",
          "modelPlural": "region_emission_factors",
          "name": "regionEmissionFactor",
          "pluralName": "regionEmissionFactors",
          "readableName": "Region emission factor",
          "pluralReadableName": "Region emission factors",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/region_emission_factors",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "country": {
            "x-rhino-attribute": {
              "name": "country",
              "readableName": "Country",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "enum": [
              "canada",
              "us"
            ],
            "format": "country"
          },
          "region": {
            "x-rhino-attribute": {
              "name": "region",
              "readableName": "Region",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "egrid_acronym": {
            "x-rhino-attribute": {
              "name": "egrid_acronym",
              "readableName": "Egrid Acronym",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "year": {
            "x-rhino-attribute": {
              "name": "year",
              "readableName": "Year",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "fuel_emission_factors": {
            "x-rhino-attribute": {
              "name": "fuel_emission_factors",
              "readableName": "Fuel Emission Factors",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/fuel_emission_factor"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        }
      },
      "renovation_technique": {
        "x-rhino-model": {
          "model": "renovation_technique",
          "modelPlural": "renovation_techniques",
          "name": "renovationTechnique",
          "pluralName": "renovationTechniques",
          "readableName": "Renovation technique",
          "pluralReadableName": "Renovation techniques",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/renovation_techniques",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "technique": {
            "x-rhino-attribute": {
              "name": "technique",
              "readableName": "Technique",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "is_summarizable": {
            "x-rhino-attribute": {
              "name": "is_summarizable",
              "readableName": "Is Summarizable",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "base_element": {
            "x-rhino-attribute": {
              "name": "base_element",
              "readableName": "Base Element",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/base_element"
              }
            ]
          },
          "technique_costs": {
            "x-rhino-attribute": {
              "name": "technique_costs",
              "readableName": "Technique Costs",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": true,
            "type": "array",
            "items": {
              "type": "reference",
              "anyOf": [
                {
                  "$ref": "#/components/schemas/technique_cost"
                }
              ],
              "x-rhino-attribute-array": {
              }
            }
          }
        },
        "required": [
          "technique",
          "base_element"
        ]
      },
      "energy_source": {
        "x-rhino-model": {
          "model": "energy_source",
          "modelPlural": "energy_sources",
          "name": "energySource",
          "pluralName": "energySources",
          "readableName": "Energy source",
          "pluralReadableName": "Energy sources",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/energy_sources",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "source_name": {
            "x-rhino-attribute": {
              "name": "source_name",
              "readableName": "Source Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "column_name": {
            "x-rhino-attribute": {
              "name": "column_name",
              "readableName": "Column Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "is_cooling": {
            "x-rhino-attribute": {
              "name": "is_cooling",
              "readableName": "Is Cooling",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          },
          "is_heating": {
            "x-rhino-attribute": {
              "name": "is_heating",
              "readableName": "Is Heating",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": false,
            "type": "boolean"
          }
        }
      },
      "technique_cost": {
        "x-rhino-model": {
          "model": "technique_cost",
          "modelPlural": "technique_costs",
          "name": "techniqueCost",
          "pluralName": "techniqueCosts",
          "readableName": "Technique cost",
          "pluralReadableName": "Technique costs",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/technique_costs",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "cost": {
            "x-rhino-attribute": {
              "name": "cost",
              "readableName": "Cost",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 0.01
          },
          "target_uvalue": {
            "x-rhino-attribute": {
              "name": "target_uvalue",
              "readableName": "Target Uvalue",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "float",
            "minimum": 0.01
          },
          "currency": {
            "x-rhino-attribute": {
              "name": "currency",
              "readableName": "Currency",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string",
            "enum": [
              "usd",
              "cad",
              "jpy"
            ]
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "renovation_technique": {
            "x-rhino-attribute": {
              "name": "renovation_technique",
              "readableName": "Renovation Technique",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/renovation_technique"
              }
            ]
          }
        },
        "required": [
          "cost",
          "target_uvalue",
          "renovation_technique"
        ]
      },
      "ai_model": {
        "x-rhino-model": {
          "model": "ai_model",
          "modelPlural": "ai_models",
          "name": "aiModel",
          "pluralName": "aiModels",
          "readableName": "Ai model",
          "pluralReadableName": "Ai models",
          "ownedBy": "global",
          "singular": false,
          "path": "/api/ai_models",
          "searchable": false
        },
        "type": "object",
        "properties": {
          "id": {
            "x-rhino-attribute": {
              "name": "id",
              "readableName": "Id",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "identifier"
          },
          "name": {
            "x-rhino-attribute": {
              "name": "name",
              "readableName": "Name",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "key": {
            "x-rhino-attribute": {
              "name": "key",
              "readableName": "Key",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "type": "string"
          },
          "created_at": {
            "x-rhino-attribute": {
              "name": "created_at",
              "readableName": "Created At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "updated_at": {
            "x-rhino-attribute": {
              "name": "updated_at",
              "readableName": "Updated At",
              "readable": true,
              "creatable": false,
              "updatable": false
            },
            "readOnly": true,
            "nullable": false,
            "type": "string",
            "format": "datetime"
          },
          "max_box": {
            "x-rhino-attribute": {
              "name": "max_box",
              "readableName": "Max Box",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 50,
            "type": "integer"
          },
          "score": {
            "x-rhino-attribute": {
              "name": "score",
              "readableName": "Score",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 20,
            "type": "integer"
          },
          "overlap": {
            "x-rhino-attribute": {
              "name": "overlap",
              "readableName": "Overlap",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": true,
            "default": 45,
            "type": "integer"
          },
          "note": {
            "x-rhino-attribute": {
              "name": "note",
              "readableName": "Note",
              "readable": true,
              "creatable": true,
              "updatable": true
            },
            "nullable": false,
            "type": "reference",
            "anyOf": [
              {
                "$ref": "#/components/schemas/note"
              }
            ]
          }
        },
        "required": [
          "note"
        ]
      }
    }
  },
  "paths": {
    "/api/building_operations/:id/copy_reflectives(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      },
      "put": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/formula_migrate(.:format)": {
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/recalculate_boxes(.:format)": {
      "post": {
        "operationId": "building_operation-recalculate_boxes",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/facades_position_change(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/generate_two_d_model(.:format)": {
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/zone_maps(.:format)": {
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/zone_maps/:zone_map_id(.:format)": {
      "delete": {
        "operationId": "building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          },
          {
            "name": "zone_map_id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/field_data(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/elements(.:format)": {
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/elements/:element_id(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          },
          {
            "name": "element_id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      },
      "delete": {
        "operationId": "building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          },
          {
            "name": "element_id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/facades/:id/upload(.:format)": {
      "patch": {
        "operationId": "facade-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      },
      "put": {
        "operationId": "facade-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      }
    },
    "/api/facades/:id/clear(.:format)": {
      "delete": {
        "operationId": "facade-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      }
    },
    "/api/facades/:id/clear_images(.:format)": {
      "patch": {
        "operationId": "facade-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      }
    },
    "/api/cells/:id/clear_image(.:format)": {
      "patch": {
        "operationId": "cell-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell"
        ]
      }
    },
    "/api/building_images/:id/tune(.:format)": {
      "patch": {
        "operationId": "building_image-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      },
      "put": {
        "operationId": "building_image-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      }
    },
    "/api/building_images/:id/assign_image(.:format)": {
      "patch": {
        "operationId": "building_image-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      }
    },
    "/api/building_images/:id/reset(.:format)": {
      "patch": {
        "operationId": "building_image-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      },
      "put": {
        "operationId": "building_image-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      }
    },
    "/api/building_images/:id/thermal_preview(.:format)": {
      "get": {
        "operationId": "building_image-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      }
    },
    "/api/cell_element_details/:id/generate_thermals(.:format)": {
      "patch": {
        "operationId": "cell_element_detail-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell_element_detail"
        ]
      }
    },
    "/api/building_operations/index(.:format)": {
      "get": {
        "operationId": "building_operation-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/curation_download(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/qtool_images_download(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/report_check(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/annualization(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/emissions/:emission_id(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          },
          {
            "name": "emission_id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      },
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          },
          {
            "name": "emission_id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/emissions(.:format)": {
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/annualization_snapshots(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/annualization_snapshots/:snapshot_id(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          },
          {
            "name": "snapshot_id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/from_existing/:id(.:format)": {
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/elq_export(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/building_images(.:format)": {
      "get": {
        "operationId": "building_operation-index",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-cover(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/issues/:id/upload-visual(.:format)": {
      "patch": {
        "operationId": "issue-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/issue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "issue"
        ]
      }
    },
    "/api/cell_element_details/:id/thermal_csv_download(.:format)": {
      "get": {
        "operationId": "cell_element_detail-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell_element_detail"
        ]
      }
    },
    "/api/facade_element_reflectives/:id/immediate(.:format)": {
      "get": {
        "operationId": "facade_element_detail-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_detail"
        ]
      }
    },
    "/api/report_sets/:id/report_set_approve_retract(.:format)": {
      "patch": {
        "operationId": "report_set-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      },
      "put": {
        "operationId": "report_set-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      }
    },
    "/api/report_sets(.:format)": {
      "post": {
        "operationId": "report_set-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      }
    },
    "/api/report_sets/:id/set_portal(.:format)": {
      "patch": {
        "operationId": "report_set-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      }
    },
    "/api/facades/:id/bulk_update(.:format)": {
      "patch": {
        "operationId": "facade-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      }
    },
    "/api/facades/:id/bulk_delete(.:format)": {
      "delete": {
        "operationId": "facade-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      }
    },
    "/api/facades/:id/building_images(.:format)": {
      "delete": {
        "operationId": "facade-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      }
    },
    "/api/create_organization(.:format)": {
      "post": {
        "operationId": "organization-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/organization"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "organization"
        ]
      }
    },
    "/api/building_operations/:id/migrate_note(.:format)": {
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/portal_controls(.:format)": {
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/portal_controls/:portal_control_id(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          },
          {
            "name": "portal_control_id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-class-d-report(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-class-d-report/purge(.:format)": {
      "delete": {
        "operationId": "building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/energy_consumptions(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/analysis_results(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/energy_calculate(.:format)": {
      "post": {
        "operationId": "building_operation-calculate",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/energy_consumptions/:id(.:format)": {
      "patch": {
        "operationId": "energy_consumption-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/energy_consumption"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "energy_consumption"
        ]
      }
    },
    "/api/building_operations/:id/upload-energy-analysis(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-energy-analysis/purge(.:format)": {
      "delete": {
        "operationId": "building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/class_d(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      },
      "post": {
        "operationId": "building_operation-create",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/extract_ml(.:format)": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-assumption-summary(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-assumption-summary/purge(.:format)": {
      "delete": {
        "operationId": "building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-estimated-area(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-estimated-area/purge(.:format)": {
      "delete": {
        "operationId": "building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-customer-logo(.:format)": {
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id/upload-customer-logo/purge(.:format)": {
      "delete": {
        "operationId": "building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/attachments": {
      "get": {
        "operationId": "active_storage_attachment-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "active_storage_attachment"
        ]
      },
      "post": {
        "operationId": "active_storage_attachment-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "active_storage_attachment"
        ]
      }
    },
    "/api/attachments/:id": {
      "get": {
        "operationId": "active_storage_attachment-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "active_storage_attachment"
        ]
      },
      "patch": {
        "operationId": "active_storage_attachment-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "active_storage_attachment"
        ]
      },
      "put": {
        "operationId": "active_storage_attachment-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "active_storage_attachment"
        ]
      },
      "delete": {
        "operationId": "active_storage_attachment-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/active_storage_attachment"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "active_storage_attachment"
        ]
      }
    },
    "/api/users": {
      "get": {
        "operationId": "user-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/user"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "user"
        ]
      },
      "post": {
        "operationId": "user-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/user"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "user"
        ]
      }
    },
    "/api/users/:id": {
      "get": {
        "operationId": "user-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/user"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "user"
        ]
      },
      "patch": {
        "operationId": "user-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/user"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "user"
        ]
      },
      "put": {
        "operationId": "user-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/user"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "user"
        ]
      },
      "delete": {
        "operationId": "user-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/user"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "user"
        ]
      }
    },
    "/api/account": {
      "get": {
        "operationId": "account-show",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/account"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "account"
        ]
      },
      "patch": {
        "operationId": "account-update",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/account"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "account"
        ]
      },
      "put": {
        "operationId": "account-update",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/account"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "account"
        ]
      }
    },
    "/api/currencies": {
      "get": {
        "operationId": "currency-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/currency"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "currency"
        ]
      }
    },
    "/api/currencies/:id": {
      "get": {
        "operationId": "currency-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/currency"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "currency"
        ]
      }
    },
    "/api/organizations": {
      "get": {
        "operationId": "organization-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/organization"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "organization"
        ]
      },
      "post": {
        "operationId": "organization-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/organization"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "organization"
        ]
      }
    },
    "/api/organizations/:id": {
      "get": {
        "operationId": "organization-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/organization"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "organization"
        ]
      },
      "patch": {
        "operationId": "organization-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/organization"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "organization"
        ]
      },
      "put": {
        "operationId": "organization-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/organization"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "organization"
        ]
      },
      "delete": {
        "operationId": "organization-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/organization"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "organization"
        ]
      }
    },
    "/api/users_roles": {
      "get": {
        "operationId": "users_role-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role"
        ]
      },
      "post": {
        "operationId": "users_role-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role"
        ]
      }
    },
    "/api/users_roles/:id": {
      "get": {
        "operationId": "users_role-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role"
        ]
      },
      "patch": {
        "operationId": "users_role-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role"
        ]
      },
      "put": {
        "operationId": "users_role-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role"
        ]
      },
      "delete": {
        "operationId": "users_role-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role"
        ]
      }
    },
    "/api/roles": {
      "get": {
        "operationId": "role-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/role"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "role"
        ]
      }
    },
    "/api/roles/:id": {
      "get": {
        "operationId": "role-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/role"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "role"
        ]
      }
    },
    "/api/users_role_invites": {
      "get": {
        "operationId": "users_role_invite-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role_invite"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role_invite"
        ]
      },
      "post": {
        "operationId": "users_role_invite-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role_invite"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role_invite"
        ]
      }
    },
    "/api/users_role_invites/:id": {
      "get": {
        "operationId": "users_role_invite-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role_invite"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role_invite"
        ]
      },
      "patch": {
        "operationId": "users_role_invite-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role_invite"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role_invite"
        ]
      },
      "put": {
        "operationId": "users_role_invite-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role_invite"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role_invite"
        ]
      },
      "delete": {
        "operationId": "users_role_invite-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/users_role_invite"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "users_role_invite"
        ]
      }
    },
    "/api/sites": {
      "get": {
        "operationId": "site-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "site"
        ]
      },
      "post": {
        "operationId": "site-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "site"
        ]
      }
    },
    "/api/sites/:id": {
      "get": {
        "operationId": "site-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "site"
        ]
      },
      "patch": {
        "operationId": "site-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "site"
        ]
      },
      "put": {
        "operationId": "site-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "site"
        ]
      },
      "delete": {
        "operationId": "site-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "site"
        ]
      }
    },
    "/api/buildings": {
      "get": {
        "operationId": "building-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building"
        ]
      },
      "post": {
        "operationId": "building-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building"
        ]
      }
    },
    "/api/buildings/:id": {
      "get": {
        "operationId": "building-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building"
        ]
      },
      "patch": {
        "operationId": "building-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building"
        ]
      },
      "put": {
        "operationId": "building-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building"
        ]
      },
      "delete": {
        "operationId": "building-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building"
        ]
      }
    },
    "/api/building_types": {
      "get": {
        "operationId": "building_type-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_type"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_type"
        ]
      }
    },
    "/api/building_types/:id": {
      "get": {
        "operationId": "building_type-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_type"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_type"
        ]
      }
    },
    "/api/building_codes": {
      "get": {
        "operationId": "building_code-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code"
        ]
      },
      "post": {
        "operationId": "building_code-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code"
        ]
      }
    },
    "/api/building_codes/:id": {
      "get": {
        "operationId": "building_code-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code"
        ]
      },
      "patch": {
        "operationId": "building_code-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code"
        ]
      },
      "put": {
        "operationId": "building_code-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code"
        ]
      },
      "delete": {
        "operationId": "building_code-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code"
        ]
      }
    },
    "/api/building_code_uvalues": {
      "get": {
        "operationId": "building_code_uvalue-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code_uvalue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code_uvalue"
        ]
      },
      "post": {
        "operationId": "building_code_uvalue-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code_uvalue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code_uvalue"
        ]
      }
    },
    "/api/building_code_uvalues/:id": {
      "get": {
        "operationId": "building_code_uvalue-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code_uvalue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code_uvalue"
        ]
      },
      "patch": {
        "operationId": "building_code_uvalue-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code_uvalue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code_uvalue"
        ]
      },
      "put": {
        "operationId": "building_code_uvalue-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code_uvalue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code_uvalue"
        ]
      },
      "delete": {
        "operationId": "building_code_uvalue-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_code_uvalue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_code_uvalue"
        ]
      }
    },
    "/api/building_accesses": {
      "get": {
        "operationId": "building_access-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_access"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_access"
        ]
      },
      "post": {
        "operationId": "building_access-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_access"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_access"
        ]
      }
    },
    "/api/building_accesses/:id": {
      "get": {
        "operationId": "building_access-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_access"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_access"
        ]
      },
      "patch": {
        "operationId": "building_access-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_access"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_access"
        ]
      },
      "put": {
        "operationId": "building_access-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_access"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_access"
        ]
      },
      "delete": {
        "operationId": "building_access-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_access"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_access"
        ]
      }
    },
    "/api/zones": {
      "get": {
        "operationId": "zone-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/zone"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "zone"
        ]
      },
      "post": {
        "operationId": "zone-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/zone"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "zone"
        ]
      }
    },
    "/api/zones/:id": {
      "get": {
        "operationId": "zone-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/zone"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "zone"
        ]
      },
      "patch": {
        "operationId": "zone-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/zone"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "zone"
        ]
      },
      "put": {
        "operationId": "zone-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/zone"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "zone"
        ]
      },
      "delete": {
        "operationId": "zone-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/zone"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "zone"
        ]
      }
    },
    "/api/facades": {
      "get": {
        "operationId": "facade-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      },
      "post": {
        "operationId": "facade-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      }
    },
    "/api/facades/:id": {
      "get": {
        "operationId": "facade-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      },
      "patch": {
        "operationId": "facade-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      },
      "put": {
        "operationId": "facade-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      },
      "delete": {
        "operationId": "facade-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade"
        ]
      }
    },
    "/api/cells": {
      "get": {
        "operationId": "cell-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell"
        ]
      },
      "post": {
        "operationId": "cell-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell"
        ]
      }
    },
    "/api/cells/:id": {
      "get": {
        "operationId": "cell-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell"
        ]
      },
      "patch": {
        "operationId": "cell-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell"
        ]
      },
      "put": {
        "operationId": "cell-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell"
        ]
      },
      "delete": {
        "operationId": "cell-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell"
        ]
      }
    },
    "/api/facade_element_details": {
      "get": {
        "operationId": "facade_element_detail-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_detail"
        ]
      },
      "post": {
        "operationId": "facade_element_detail-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_detail"
        ]
      }
    },
    "/api/facade_element_details/:id": {
      "get": {
        "operationId": "facade_element_detail-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_detail"
        ]
      },
      "patch": {
        "operationId": "facade_element_detail-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_detail"
        ]
      },
      "put": {
        "operationId": "facade_element_detail-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_detail"
        ]
      },
      "delete": {
        "operationId": "facade_element_detail-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_detail"
        ]
      }
    },
    "/api/cell_element_details": {
      "get": {
        "operationId": "cell_element_detail-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell_element_detail"
        ]
      },
      "post": {
        "operationId": "cell_element_detail-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell_element_detail"
        ]
      }
    },
    "/api/cell_element_details/:id": {
      "get": {
        "operationId": "cell_element_detail-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell_element_detail"
        ]
      },
      "patch": {
        "operationId": "cell_element_detail-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell_element_detail"
        ]
      },
      "put": {
        "operationId": "cell_element_detail-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell_element_detail"
        ]
      },
      "delete": {
        "operationId": "cell_element_detail-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/cell_element_detail"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "cell_element_detail"
        ]
      }
    },
    "/api/facade_element_reflectives": {
      "get": {
        "operationId": "facade_element_reflective-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_reflective"
        ]
      },
      "post": {
        "operationId": "facade_element_reflective-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_reflective"
        ]
      }
    },
    "/api/facade_element_reflectives/:id": {
      "get": {
        "operationId": "facade_element_reflective-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_reflective"
        ]
      },
      "patch": {
        "operationId": "facade_element_reflective-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_reflective"
        ]
      },
      "put": {
        "operationId": "facade_element_reflective-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_reflective"
        ]
      },
      "delete": {
        "operationId": "facade_element_reflective-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/facade_element_reflective"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "facade_element_reflective"
        ]
      }
    },
    "/api/building_operations": {
      "get": {
        "operationId": "building_operation-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      },
      "post": {
        "operationId": "building_operation-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_operations/:id": {
      "get": {
        "operationId": "building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      },
      "patch": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      },
      "put": {
        "operationId": "building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      },
      "delete": {
        "operationId": "building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_operation"
        ]
      }
    },
    "/api/building_images": {
      "get": {
        "operationId": "building_image-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      },
      "post": {
        "operationId": "building_image-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      }
    },
    "/api/building_images/:id": {
      "get": {
        "operationId": "building_image-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      },
      "patch": {
        "operationId": "building_image-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      },
      "put": {
        "operationId": "building_image-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      },
      "delete": {
        "operationId": "building_image-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/building_image"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "building_image"
        ]
      }
    },
    "/api/quantitative_image_elements": {
      "get": {
        "operationId": "quantitative_image_element-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/quantitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "quantitative_image_element"
        ]
      },
      "post": {
        "operationId": "quantitative_image_element-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/quantitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "quantitative_image_element"
        ]
      }
    },
    "/api/quantitative_image_elements/:id": {
      "get": {
        "operationId": "quantitative_image_element-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/quantitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "quantitative_image_element"
        ]
      },
      "patch": {
        "operationId": "quantitative_image_element-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/quantitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "quantitative_image_element"
        ]
      },
      "put": {
        "operationId": "quantitative_image_element-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/quantitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "quantitative_image_element"
        ]
      },
      "delete": {
        "operationId": "quantitative_image_element-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/quantitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "quantitative_image_element"
        ]
      }
    },
    "/api/qualitative_image_elements": {
      "get": {
        "operationId": "qualitative_image_element-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/qualitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "qualitative_image_element"
        ]
      },
      "post": {
        "operationId": "qualitative_image_element-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/qualitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "qualitative_image_element"
        ]
      }
    },
    "/api/qualitative_image_elements/:id": {
      "get": {
        "operationId": "qualitative_image_element-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/qualitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "qualitative_image_element"
        ]
      },
      "patch": {
        "operationId": "qualitative_image_element-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/qualitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "qualitative_image_element"
        ]
      },
      "put": {
        "operationId": "qualitative_image_element-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/qualitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "qualitative_image_element"
        ]
      },
      "delete": {
        "operationId": "qualitative_image_element-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/qualitative_image_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "qualitative_image_element"
        ]
      }
    },
    "/api/base_elements": {
      "get": {
        "operationId": "base_element-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/base_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "base_element"
        ]
      }
    },
    "/api/base_elements/:id": {
      "get": {
        "operationId": "base_element-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/base_element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "base_element"
        ]
      }
    },
    "/api/element_libraries": {
      "get": {
        "operationId": "element_library-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/element_library"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "element_library"
        ]
      }
    },
    "/api/element_libraries/:id": {
      "get": {
        "operationId": "element_library-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/element_library"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "element_library"
        ]
      }
    },
    "/api/elements": {
      "get": {
        "operationId": "element-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "element"
        ]
      },
      "post": {
        "operationId": "element-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "element"
        ]
      }
    },
    "/api/elements/:id": {
      "get": {
        "operationId": "element-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "element"
        ]
      },
      "patch": {
        "operationId": "element-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "element"
        ]
      },
      "put": {
        "operationId": "element-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "element"
        ]
      },
      "delete": {
        "operationId": "element-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/element"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "element"
        ]
      }
    },
    "/api/note_categories": {
      "get": {
        "operationId": "note_category-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/note_category"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "note_category"
        ]
      }
    },
    "/api/note_categories/:id": {
      "get": {
        "operationId": "note_category-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/note_category"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "note_category"
        ]
      }
    },
    "/api/notes": {
      "get": {
        "operationId": "note-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/note"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "note"
        ]
      },
      "post": {
        "operationId": "note-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/note"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "note"
        ]
      }
    },
    "/api/notes/:id": {
      "get": {
        "operationId": "note-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/note"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "note"
        ]
      },
      "patch": {
        "operationId": "note-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/note"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "note"
        ]
      },
      "put": {
        "operationId": "note-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/note"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "note"
        ]
      },
      "delete": {
        "operationId": "note-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/note"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "note"
        ]
      }
    },
    "/api/report_sets": {
      "get": {
        "operationId": "report_set-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      },
      "post": {
        "operationId": "report_set-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      }
    },
    "/api/report_sets/:id": {
      "get": {
        "operationId": "report_set-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      },
      "patch": {
        "operationId": "report_set-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      },
      "put": {
        "operationId": "report_set-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      },
      "delete": {
        "operationId": "report_set-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report_set"
        ]
      }
    },
    "/api/reports": {
      "get": {
        "operationId": "report-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report"
        ]
      },
      "post": {
        "operationId": "report-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report"
        ]
      }
    },
    "/api/reports/:id": {
      "get": {
        "operationId": "report-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report"
        ]
      },
      "patch": {
        "operationId": "report-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report"
        ]
      },
      "put": {
        "operationId": "report-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report"
        ]
      },
      "delete": {
        "operationId": "report-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/report"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "report"
        ]
      }
    },
    "/api/cities": {
      "get": {
        "operationId": "city-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/city"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "city"
        ]
      }
    },
    "/api/cities/:id": {
      "get": {
        "operationId": "city-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/city"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "city"
        ]
      }
    },
    "/api/customer_sites": {
      "get": {
        "operationId": "customer_site-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_site"
        ]
      },
      "post": {
        "operationId": "customer_site-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_site"
        ]
      }
    },
    "/api/customer_sites/:id": {
      "get": {
        "operationId": "customer_site-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_site"
        ]
      },
      "patch": {
        "operationId": "customer_site-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_site"
        ]
      },
      "put": {
        "operationId": "customer_site-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_site"
        ]
      },
      "delete": {
        "operationId": "customer_site-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_site"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_site"
        ]
      }
    },
    "/api/customer_buildings": {
      "get": {
        "operationId": "customer_building-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building"
        ]
      },
      "post": {
        "operationId": "customer_building-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building"
        ]
      }
    },
    "/api/customer_buildings/:id": {
      "get": {
        "operationId": "customer_building-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building"
        ]
      },
      "patch": {
        "operationId": "customer_building-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building"
        ]
      },
      "put": {
        "operationId": "customer_building-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building"
        ]
      },
      "delete": {
        "operationId": "customer_building-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building"
        ]
      }
    },
    "/api/customer_building_operations": {
      "get": {
        "operationId": "customer_building_operation-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building_operation"
        ]
      },
      "post": {
        "operationId": "customer_building_operation-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building_operation"
        ]
      }
    },
    "/api/customer_building_operations/:id": {
      "get": {
        "operationId": "customer_building_operation-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building_operation"
        ]
      },
      "patch": {
        "operationId": "customer_building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building_operation"
        ]
      },
      "put": {
        "operationId": "customer_building_operation-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building_operation"
        ]
      },
      "delete": {
        "operationId": "customer_building_operation-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_building_operation"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_building_operation"
        ]
      }
    },
    "/api/customer_report_sets": {
      "get": {
        "operationId": "customer_report_set-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_report_set"
        ]
      },
      "post": {
        "operationId": "customer_report_set-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_report_set"
        ]
      }
    },
    "/api/customer_report_sets/:id": {
      "get": {
        "operationId": "customer_report_set-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_report_set"
        ]
      },
      "patch": {
        "operationId": "customer_report_set-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_report_set"
        ]
      },
      "put": {
        "operationId": "customer_report_set-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_report_set"
        ]
      },
      "delete": {
        "operationId": "customer_report_set-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/customer_report_set"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "customer_report_set"
        ]
      }
    },
    "/api/issues": {
      "get": {
        "operationId": "issue-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/issue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "issue"
        ]
      },
      "post": {
        "operationId": "issue-create",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/issue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "issue"
        ]
      }
    },
    "/api/issues/:id": {
      "get": {
        "operationId": "issue-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/issue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "issue"
        ]
      },
      "patch": {
        "operationId": "issue-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/issue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "issue"
        ]
      },
      "put": {
        "operationId": "issue-update",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/issue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "issue"
        ]
      },
      "delete": {
        "operationId": "issue-destroy",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/issue"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "issue"
        ]
      }
    },
    "/api/fuel_emission_factors": {
      "get": {
        "operationId": "fuel_emission_factor-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/fuel_emission_factor"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "fuel_emission_factor"
        ]
      }
    },
    "/api/fuel_emission_factors/:id": {
      "get": {
        "operationId": "fuel_emission_factor-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/fuel_emission_factor"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "fuel_emission_factor"
        ]
      }
    },
    "/api/region_emission_factors": {
      "get": {
        "operationId": "region_emission_factor-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/region_emission_factor"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "region_emission_factor"
        ]
      }
    },
    "/api/region_emission_factors/:id": {
      "get": {
        "operationId": "region_emission_factor-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/region_emission_factor"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "region_emission_factor"
        ]
      }
    },
    "/api/renovation_techniques": {
      "get": {
        "operationId": "renovation_technique-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/renovation_technique"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "renovation_technique"
        ]
      }
    },
    "/api/renovation_techniques/:id": {
      "get": {
        "operationId": "renovation_technique-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/renovation_technique"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "renovation_technique"
        ]
      }
    },
    "/api/energy_sources": {
      "get": {
        "operationId": "energy_source-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/energy_source"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "energy_source"
        ]
      }
    },
    "/api/energy_sources/:id": {
      "get": {
        "operationId": "energy_source-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/energy_source"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "energy_source"
        ]
      }
    },
    "/api/technique_costs": {
      "get": {
        "operationId": "technique_cost-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/technique_cost"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "technique_cost"
        ]
      }
    },
    "/api/technique_costs/:id": {
      "get": {
        "operationId": "technique_cost-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/technique_cost"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "technique_cost"
        ]
      }
    },
    "/api/ai_models": {
      "get": {
        "operationId": "ai_model-index",
        "parameters": [

        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ai_model"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "ai_model"
        ]
      }
    },
    "/api/ai_models/:id": {
      "get": {
        "operationId": "ai_model-show",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ai_model"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "application/json": {
              }
            }
          },
          "403": {
            "description": "Forbidden",
            "content": {
              "application/json": {
              }
            }
          },
          "422": {
            "description": "Unprocessable Entity",
            "content": {
              "application/json": {
              }
            }
          }
        },
        "tags": [
          "ai_model"
        ]
      }
    }
  },
  "info": {
    "title": "Qeatech API",
    "version": "0.0.0",
    "x-rhino": {
      "modules": {
        "rhino": {
          "version": "0.2.0",
          "authOwner": "user",
          "baseOwner": "organization",
          "oauth": [
            "google_oauth2"
          ],
          "allow_signup": false
        },
        "rhino_organizations": {
          "version": "0.1.0"
        },
        "rhino_jobs": {
          "version": "0.1.0"
        },
        "rhino_notifications": {
          "version": "0.1.0"
        }
      }
    }
  }
};

export default api;
