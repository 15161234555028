import React from 'react';
import { render } from 'react-dom';
import reportWebVitals from './reportWebVitals';

import modelLoader from 'rhino/models';

import './styles/styles.scss';

modelLoader.loadModels().then(async () => {
  // Import the Root dynamically so that other modelLoader uses are assured
  // to have access to the already loaded models
  const { default: Root } = await import('./Root.js');

  render(<Root />, document.getElementById('root'));
});

reportWebVitals();
